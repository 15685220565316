import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import AuthContext from '../../../../product/context/auth/authContext'
import CommonContext from '../../../../product/context/common/commonContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import NorthCapitalContext from '../../../../product/context/payment/northcapital/northcapitalContext'
import { handleRedirectInternal } from '../../../../product/common/components'
import './Header.css'
import ProductContext from '../../../../product/context/product/productContext'
import BidHistory from '../../../components/organisms/BidHistory'
import TTWBidHistory from '../../../components/organisms/TTWBidHistory'
import KYCUpdate from '../../../components/organisms/KYCUpdate'
import BuynowContext from '../../../../product/context/buynow/buynowContext'
import Loaders from '../Loaders'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import { IconButton } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { LOGO, LOGO_WHITE } from '../../../../Utils'
import SideNavBar from '../SideNavBar'
import FreeShares from '../../organisms/FreeShares'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import FormDialog from '../../../../product/components/organisms/Dialog'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 9999,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}))

const Header = () => {
    const classes = useStyles()
    const [aucopen, setAucOpen] = React.useState(false)
    const [menu, setMenu] = React.useState('auction')
    const anchorRef = React.useRef(null)
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const buynowContext = useContext(BuynowContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { setAlert } = alertContext
    const {
        user,
        isAuthenticated,
        loadPendingCount,
        loadPendingTempCount,
        isAdmin,
        cartTempCount,
        cartCount,
        logout,
    } = authContext
    const { getOffers, freeOffers, clearOffers } = customCommonContext
    const [freePopup, setFreePopup] = useState(false)
    const [freeData, setFreeData] = useState([])
    const [welcomePopup, setWelcomePopup] = useState(false)

    const [state, setState] = useState({
        right: false,
        top: false,
    })
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [anchorcart, setanchorcart] = useState(null)
    const [initLoad, setInitLoad] = useState(false)

    const { getAllSearchProducts, clearResponse } = productContext

    const { getAllCartProducts, search_allproducts } = buynowContext
    const { northCapitalUser } = useContext(NorthCapitalContext)

    const { searchValue, setSearchValue, isLoading } = commonContext
    const [searchText, setSearchText] = useState('')
    let [cartProducts, setCartProducts] = useState([])
    const [search, setSearch] = useState({})
    const [searchBox, setSearchBox] = useState(false)
    const [params, setParams] = useState(null)
    const location = useLocation()
    const mainMenu = useRef()

    const mobile = useMediaQuery('(max-width:1200px)')

    const mobileSmall = useMediaQuery('(max-width:700px)')
    useEffect(async () => {
        let match,
            pl = /\+/g,
            search = /([^&=]+)=?([^&]*)/g,
            decode = function (s) {
                return decodeURIComponent(s.replace(pl, ' '))
            },
            query = window.location.search.substring(1)
        let urlParams = {}
        while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])
        if (urlParams.search) {
            setSearchText(urlParams.search)
            if (location.pathname == '/search') {
                setInitLoad(true)
            }
        }
    }, [])
    useEffect(() => {
        if (initLoad) {
            setTimeout(function () {
                handleSearchButtonClick()
            }, 2000)
            setInitLoad(false)
        }
    }, [initLoad])
    const handleSearchBox = () => {
        setSearchBox(!searchBox)
    }

    const enableOneof = false

    useEffect(() => {
        if (isAuthenticated) {
            getAllCartProducts(search)

            if (enableOneof) {
                getOffers({
                    email: user?.email ? user?.email : '',
                })
            }

            if (history.location.search.includes('welcome=1')) {
                setWelcomePopup(true)
            }
        }
    }, [isAuthenticated, user])

    useEffect(() => {
        // console.log(search_allproducts.cartrecords)
        setCartProducts(
            search_allproducts.cartrecords.length && search_allproducts.cartrecords[0].cartItems
                ? search_allproducts.cartrecords[0].cartItems
                : [],
        )
    }, [search_allproducts])

    useEffect(() => {
        setCartProducts([])
        return () => {
            setCartProducts([])
        }
    }, [])

    const onLogOut = () => {
        logout()
        localStorage.removeItem('popup')
        setCount({
            auctionCount: 0,
            buynowCount: 0,
        })
        localStorage.removeItem('temp_user_id')
        localStorage.removeItem('ttw_temp_user_id')
        localStorage.removeItem('bidIncrease')
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
        setanchormenu(null)
        setanchorcart(null)
        setSearchText('')
        clearOffers()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleCart = (event) => {
        setanchorcart(event.currentTarget)
    }
    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        setanchorcart(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const listHandle = (value) => {
        let search = {
            filters: {
                auctiontype: {
                    value:
                        value == 'all_closed' || value == 'all'
                            ? ''
                            : value === 'all_ttw_closed'
                            ? 'ttw'
                            : value,
                    type: 'in',
                    field: 'p.auctiontype',
                },
            },
            market_status: ['open'],
            from: 'search',
        }
        if (value === 'all_closed') {
            search.market_status = ['closed', 'sold']
        } else if (value === 'all_ttw_closed') {
            search.market_status = ['sold']
        }

        return search
    }

    const getSearchBarObjet = (value) => {
        if (searchValue) {
            return {
                ...searchValue,
                filters: {
                    ...searchValue.filters,
                    searchbar: {
                        ...searchValue.filters.searchbar,
                        value: value,
                    },
                },
            }
        } else {
            return {
                limit: 12,
                page: 1,
                orderby: 'p.id, desc',
                order: '',
                filters: {
                    searchbar: {
                        value: value,
                        type: 'like',
                        field: 'p.title,p.desc_proc',
                    },
                },
            }
        }
    }

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSearchButtonClick = async () => {
        setSearchBox(!searchBox)
        if (typeof searchText != 'undefined') {
            await setSearchValue(getSearchBarObjet(searchText))
            setState({ ...state, top: false })
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
            let match,
                pl = /\+/g,
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) {
                    return decodeURIComponent(s.replace(pl, ' '))
                },
                query = window.location.search.substring(1)
            let urlParams = {}
            while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])
            urlParams.search = searchText
            let queryparam = Object.entries(urlParams)
                .map(([key, val]) => `${key}=${val}`)
                .join('&')
            history.replace({
                search: queryparam,
            })
        } else {
            // setSearch({
            //     ...search,
            //     filters: {
            //         ...search.filters,
            //         searchbar: {
            //             ...search.filters.searchbar,
            //             value: searchValue.filters.searchbar.value,
            //         },
            //     },
            // })
        }
    }

    const handleSearchKeyDown = async (e) => {
        if (e.key === 'Enter') {
            await setSearchValue(getSearchBarObjet(searchText))
            if (location.pathname != '/search') {
                handleRedirectInternal(history, 'search')
            }
            let match,
                pl = /\+/g,
                search = /([^&=]+)=?([^&]*)/g,
                decode = function (s) {
                    return decodeURIComponent(s.replace(pl, ' '))
                },
                query = window.location.search.substring(1)
            let urlParams = {}
            while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])
            urlParams.search = searchText
            let queryparam = Object.entries(urlParams)
                .map(([key, val]) => `${key}=${val}`)
                .join('&')
            history.replace({
                search: queryparam,
            })
        }
    }

    useEffect(() => {
        if (searchValue && searchValue.filters && searchValue.filters.searchbar) {
            setSearchText(searchValue.filters.searchbar.value)
        }
        // if (searchValue && searchValue.market_status) {
        //     setMenu('past_auction')
        // } else {
        //     setMenu('auction')
        // }
    }, [searchValue])

    useEffect(() => {
        if (isAuthenticated) {
            let auctionFilter = {
                table: 'buynow',
                field: 'id',
                extraField: '',
                filters: {
                    buynow_autype: {
                        value: 'live',
                        type: 'in',
                        field: 'b.buynow_autype',
                    },
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'b.paid',
                    },
                    partial: {
                        value: '0',
                        type: 'in',
                        field: 'b.partial',
                    },
                },
            }
            let buyNowFilter = {
                table: 'cart_temp',
                field: 'cart_id',
                extraField: '',
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }

            loadPendingCount(buyNowFilter, 'buynowCount')

            loadPendingCount(auctionFilter, 'auctionCount')

            if (user.email && window._learnq && window._learnq._loaded) {
                var _learnq = window._learnq || []
                console.log([
                    _learnq,
                    'identify',
                    {
                        $email: user.email,
                    },
                ])
                _learnq.push([
                    'identify',
                    {
                        $email: user.email,
                    },
                ])
            }
        } else {
            let buyNowFilter = {
                table: 'cart_temp_wl',
                field: 'cart_id',
                extraField: '',
                temp_user_id: localStorage?.temp_user_id ? localStorage?.temp_user_id : 0,
                filters: {
                    paid: {
                        value: '0',
                        type: 'in',
                        field: 'crt.paid',
                    },
                },
            }
            if (localStorage?.temp_user_id) {
                loadPendingTempCount(buyNowFilter, 'buynowCount')
            }
        }
    }, [isAuthenticated, history.location.pathname])

    const newRegistration = true

    useEffect(() => {
        if (isAuthenticated) {
            if (northCapitalUser) {
                // if (!northCapitalUser.suitability) {
                //     handleRedirectInternal(history, 'suitability')
                // } else if (!northCapitalUser.kyc_review) {
                //     if (history.location.pathname === '/suitability') {
                //         handleRedirectInternal(history, 'search')
                //     }
                //     if (
                //         northCapitalUser.kycstatus !== 'Approved' &&
                //         northCapitalUser.kycstatus !== 'Auto Approved'
                //     ) {
                //         toggleFullScreenKYCPopup(true, 'new')
                //     }
                // }
                if (user.reg_step_count < 3 && !user.reg_steps_skip.includes('3')) {
                    if (newRegistration) {
                        if (user.skipReg && user.skipReg > 0) {
                            handleRedirectInternal(
                                history,
                                user.skipReg === 1 || user.skipReg == '1'
                                    ? 'account/profile'
                                    : 'search',
                            )
                        }
                    } else {
                        handleRedirectInternal(history, 'registration/Addpayment')
                    }

                    return false
                }
                if (!northCapitalUser.kyc_review) {
                    if (history.location.pathname === '/registration/uploadDocument/1') {
                        handleRedirectInternal(history, 'search')
                    }
                    if (!newRegistration) {
                        // we no longer need this.
                        if (user.is_document_later === 0) {
                            if (user.user_type === 0 && user.domesticYN === 'domestic_account') {
                            } else {
                                handleRedirectInternal(history, 'registration/uploadDocument/1')
                            }
                        }
                    }
                    /*if (
                        northCapitalUser.kycstatus !== 'Approved' &&
                        northCapitalUser.kycstatus !== 'Auto Approved'
                    ) {
                        if (
                            user.kyc_request_count !== 2 &&
                            user.domesticYN === 'domestic_account'
                        ) {
                            handleRedirectInternal(history, 'registration/reupdate')
                        } else {
                            if (user.is_document_later === 0) {
                                handleRedirectInternal(history, 'registration/uploadDocument/1')
                            }
                        }
                    }*/
                }
            }
        }
    }, [isAuthenticated, northCapitalUser, history.location.pathname])

    const [count, setCount] = useState({
        auctionCount: 0,
        buynowCount: 0,
    })

    useEffect(() => {
        if (cartCount?.auctionCount) {
            setCount({ ...count, auctionCount: cartCount.auctionCount.totalRecords })
        }
        if (cartCount?.buynowCount) {
            setCount({ ...count, buynowCount: cartCount.buynowCount.totalRecords })
        }
    }, [cartCount])

    useEffect(() => {
        if (cartTempCount?.buynowCount && !isAuthenticated && localStorage.temp_user_id) {
            setCount({ ...count, buynowCount: cartTempCount.buynowCount.totalRecords })
            if (cartTempCount.buynowCount.totalRecords) {
                localStorage.setItem('cartTempCount', cartTempCount.buynowCount.totalRecords)
            } else {
                localStorage.removeItem('cartTempCount')
            }
        } else {
            setCount({
                auctionCount: 0,
                buynowCount: 0,
            })
            localStorage.removeItem('cartTempCount')
        }
    }, [cartTempCount])

    const handleMenuToggle = (activeMenu) => {
        setAucOpen((prevOpen) => !prevOpen)
        setMenu(activeMenu)
    }

    const handleMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setAucOpen(false)
    }

    const setValue = (value) => {
        if (location.pathname !== '/search') {
            handleRedirectInternal(history, 'search')
        }
        {
            window.location.pathname == '/search'
                ? (getAllSearchProducts(listHandle(value)),
                  setAucOpen(false),
                  setSearchValue(listHandle(value)))
                : null
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setAucOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(aucopen)
    React.useEffect(() => {
        if (prevOpen.current === true && aucopen === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = aucopen
    }, [aucopen])

    const [manageKYC, setManageKYC] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenKYCPopup = (popup, status, data) => {
        setManageKYC({ popup, status, data })
    }

    useEffect(() => {
        if (isAuthenticated && freeOffers.length && enableOneof) {
            if (!localStorage.getItem('confirmPopup')) {
                if (!localStorage.getItem('popup')) {
                    setFreePopup(true)
                    setFreeData(freeOffers)
                    localStorage.setItem('popup', true)
                }
            }
        }
    }, [freeOffers, isAuthenticated])

    /* useEffect(() => {
        if (history.location.search.includes('welcome=1')) {
            setWelcomePopup(true)
        }
    }, []) */

    return (
        <>
            {(!isAuthenticated || mobile) && (
                <div className="srchlogoContainer">
                    {/* <img src="/assets/svg/whitevector.svg" className="logoBack" /> */}
                    <a href="https://songvest.com" id="songvest_logo">
                        <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                    </a>
                </div>
            )}
            {!mobile ? (
                <>
                    <Loaders name="home" isLoading={isLoading} />
                    <header className="mainHeader customContainer pt-3 pb-3">
                        <div className="navsContainer d-flex align-items-center justify-content-end">
                            <Link className="mnNvlLnks" to="/" id="liveinvestments_link">
                                Live Investments
                            </Link>
                            <a
                                className="mnNvlLnks"
                                href="https://www.songvest.com/aboutsongshares"
                                id="faninvestorbenefits_link"
                            >
                                Fan Investor Benefits
                            </a>
                            <a
                                className="mnNvlLnks"
                                href="https://www.songvest.com/sellroyalties"
                                id="sellingroyalties_link"
                            >
                                Selling Royalties
                            </a>
                            <a
                                className="mnNvlLnks"
                                href="https://www.songvest.com/about-us"
                                id="aboutus_link"
                            >
                                About Us
                            </a>
                            <div className={`srchContainer d-flex align-items-center ml-4`}>
                                <img className="srchIcon" src="/assets/svg/search.svg" />
                                <input
                                    id="homepage_search_inputfield"
                                    placeholder="Search"
                                    onClick={handleSearchBox}
                                    type="search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                            </div>
                            {!isAuthenticated ? (
                                <>
                                    <IconButton
                                        className="cartBtn mx-3"
                                        onClick={() => handleClose(history, 'buynowcart')}
                                        id="homepage_cart"
                                    >
                                        <Badge
                                            badgeContent={count.buynowCount || 0}
                                            color="primary"
                                        >
                                            <img src="/assets/svg/shopping-cart.svg" />
                                        </Badge>
                                    </IconButton>
                                    <div className="bfrLgnCont">
                                        <span className="material-icons ml-3 mr-2">
                                            account_circle
                                        </span>
                                        <Link to="/login" id="login_link">
                                            Login
                                        </Link>
                                        <span className="mx-2">/</span>
                                        <Link to="/register" id="register_link">
                                            Register
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <IconButton
                                        className="cartBtn mx-3"
                                        onClick={() => handleClose(history, 'buynowcart')}
                                        id="homepage_cart"
                                    >
                                        <Badge
                                            badgeContent={count.buynowCount || 0}
                                            color="primary"
                                        >
                                            <img src="/assets/svg/shopping-cart.svg" />
                                        </Badge>
                                    </IconButton>
                                    <Button
                                        ref={mainMenu}
                                        className="acntBtnDrop"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        id="homepage_profile"
                                    >
                                        <span className="material-icons mr-2">account_circle</span>
                                        Hi,{' '}
                                        {user.user_type === 0 ? user.first_name : user.companyname}
                                        <span className="material-icons ml-1">expand_more</span>
                                    </Button>
                                    <Popover
                                        id="simple-menu"
                                        anchorEl={mainMenu.current}
                                        keepMounted
                                        className={`headerDropMenu ${
                                            document.body.dir === 'rtl' && 'rtl'
                                        }`}
                                        onClose={handleClose}
                                        open={Boolean(anchormenu)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {/* <MenuItem
                                                onClick={() => handleClose(history, 'buynowcart')}
                                            >
                                                <img
                                                    src="/assets/svg/myauctions.svg"
                                                    className="mr-2"
                                                />
                                                Cart
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => handleClose(history, 'auctions/all')}
                                            >
                                                <img
                                                    src="/assets/svg/mysongshares.svg"
                                                    className="mr-2"
                                                />
                                                My SongShares
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    handleClose(history, 'account/profile')
                                                }
                                            >
                                                <span className="material-icons mr-2">person</span>
                                                Profile
                                            </MenuItem> */}
                                            <MenuItem onClick={() => onLogOut()}>
                                                <span className="material-icons mr-2">
                                                    power_settings_new
                                                </span>
                                                Logout
                                            </MenuItem>
                                        </MenuList>
                                    </Popover>
                                </>
                            )}
                        </div>
                    </header>
                </>
            ) : (
                <header className="mainHeader customContainer pt-3 pb-3">
                    <div className="navsContainer d-flex align-items-center justify-content-end">
                        {!mobileSmall ? (
                            <div className={`srchContainer d-flex align-items-center mr-3`}>
                                <img className="srchIcon" src="/assets/svg/search.svg" />
                                <input
                                    placeholder="Search"
                                    onClick={handleSearchBox}
                                    type="search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                            </div>
                        ) : (
                            <IconButton
                                className="mobMenuBtn mr-2"
                                onClick={toggleDrawer('top', true)}
                            >
                                <img className="mobsrchIcon" src="/assets/svg/search.svg" />
                            </IconButton>
                        )}
                        {isAuthenticated && (
                            <IconButton
                                className="cartBtn mr-2"
                                onClick={() => handleClose(history, 'buynowcart')}
                            >
                                <Badge badgeContent={count.buynowCount || 0} color="primary">
                                    <img src="/assets/svg/shopping-cart.svg" />
                                </Badge>
                            </IconButton>
                        )}

                        <IconButton className="mobMenuBtn" onClick={toggleDrawer('left', true)}>
                            <span className="material-icons">menu</span>
                        </IconButton>
                        <SwipeableDrawer
                            anchor={'left'}
                            open={state['left']}
                            onClose={toggleDrawer('left', false)}
                            onOpen={toggleDrawer('left', true)}
                        >
                            <SideNavBar navbarClick={toggleDrawer('left', false)} />
                        </SwipeableDrawer>
                        <SwipeableDrawer
                            className="mblSrchDrawer"
                            anchor={'top'}
                            open={state['top']}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <div className={`mblSrchContainer d-flex align-items-center`}>
                                <input
                                    placeholder="Search"
                                    onClick={handleSearchBox}
                                    type="search"
                                    value={searchText}
                                    onChange={handleSearchTextChange}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button className="btn srchBtn" onClick={handleSearchButtonClick}>
                                    <img className="srchIcon" src="/assets/svg/search.svg" />
                                </Button>
                            </div>
                        </SwipeableDrawer>
                    </div>
                </header>
            )}
            <KYCUpdate data={manageKYC} function={toggleFullScreenKYCPopup} />
            {/* <FreeShares
                open={freePopup}
                openFunction={() => setFreePopup(false)}
                data={freeData}
                user={user}
            /> */}
            <FormDialog
                className="freeSharePopup wlcmPopup"
                open={welcomePopup}
                handleClose={() => setWelcomePopup(false)}
                maxWidth={'sm'}
            >
                <div className="wlcmCntnr">
                    <img src={LOGO} />
                    <h2>Welcome To SongVest!</h2>
                    <p>Thank you for registering an account with us!</p>
                    <p>
                        You&apos;re now on your way to taking part in the success and journey of
                        some of your favorite artists.
                    </p>
                    <p>
                        We&apos;re glad to have you here and we look forward to providing you with
                        exciting offerings that you can be a part of!
                    </p>
                </div>
            </FormDialog>
            <BidHistory />
            <TTWBidHistory />
        </>
    )
}
export default Header
