import React, { useState, useContext, useEffect } from 'react'
import './login.css'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import { Button, Divider } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import EmailVerify from './emailVerify'

import { handleRedirectInternal, mapData } from '../../product/common/components'
import { LOGO } from '../../Utils'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { useLocation } from 'react-router-dom'
import AlertContext from '../../product/context/alert/alertContext'

const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { getUserDetails, getCreditCard, getLinkedCreditCard } = useContext(NorthCapitalContext)

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, 'search?auctions=1')
        }
    }, [isAuthenticated])

    const [emailVerify, setEmailVerify] = React.useState(false)

    const changeEmailVerify = () => {
        setEmailVerify(!emailVerify)
    }

    let [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
        password: Yup.string().required('Required!'),
    })

    const queryParam = useLocation().search
    let redirectTo = new URLSearchParams(queryParam).get('redirectTo')

    const formik = useFormik({
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
            verify_code: '',
            email_verified: 0,
            verified_email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }
            if (localStorage.temp_user_id) {
                values.temp_user_id = localStorage.temp_user_id
            }
            if (localStorage.ttw_temp_user_id) {
                values.ttw_temp_user_id = localStorage.ttw_temp_user_id
            }
            login(values)
        },
    })

    const rememberMe = [
        {
            label: 'Remember me',
            name: 'remember_me',
            type: 'checkbox',
            placeholder: 'Remember me',
            class: 'w-100',
            formik: formik,
        },
    ]

    const loginInfo = [
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Password',
            name: 'password',
            type: passwordShown ? 'text' : 'password',
            placeholder: 'Enter your password',
            class: 'col-12',
            formik: formik,
            upperLabel: true,
            endAdornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    if (responseStatus.data && responseStatus.data['2fa']) {
                        setEmailVerify(true)
                    } else {
                        getUserDetails()
                        // getLinkedCreditCard()
                        // getCreditCard()
                        if (
                            typeof redirectTo !== 'undefined' &&
                            redirectTo !== null &&
                            redirectTo != ''
                        ) {
                            redirectTo = redirectTo.replace(/^(\/)/, '')
                            handleRedirectInternal(history, redirectTo)
                        } else {
                            if (localStorage.temp_user_id) {
                                handleRedirectInternal(history, 'buynowcart')
                                localStorage.removeItem('temp_user_id')
                            } else if (localStorage.ttw_temp_user_id) {
                                console.log(responseStatus.data, 'status')
                                if (
                                    responseStatus?.data?.ttwRes &&
                                    responseStatus.data?.ttwRes?.length &&
                                    responseStatus.data?.ttwRes[0].message ==
                                        'Current price is increased'
                                ) {
                                    handleRedirectInternal(
                                        history,
                                        `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    )
                                    localStorage.setItem(
                                        'bidIncrease',
                                        JSON.stringify({
                                            proposed_amount: localStorage.share,
                                            id: localStorage.itemId,
                                            bidding_type: 'hard',
                                            proposed_qty: '',
                                            customBidData: {
                                                autopurchase: localStorage.autopurchase,
                                            },
                                            autopurchase: localStorage.autopurchase,
                                        }),
                                    )
                                    localStorage.setItem(
                                        'current_price',
                                        responseStatus.data?.ttwRes[0].current_price,
                                    )
                                    localStorage.setItem(
                                        'old_price',
                                        responseStatus.data?.ttwRes[0].old_price,
                                    )

                                    localStorage.removeItem('ttw_temp_user_id')
                                    localStorage.removeItem('itemId')
                                    localStorage.removeItem('itemTitle')
                                    localStorage.removeItem('share')
                                    localStorage.removeItem('autopurchase')
                                    localStorage.removeItem('current_bid')
                                } else if (
                                    responseStatus?.data?.ttwRes &&
                                    responseStatus.data?.ttwRes?.length
                                ) {
                                    setAlert(
                                        'Please add card/bank details to reserve shares',
                                        'error',
                                    )
                                    if (
                                        responseStatus.data?.ttwRes[0]?.current_price &&
                                        responseStatus.data?.ttwRes[0].current_price !=
                                            responseStatus.data?.ttwRes[0].old_price
                                    ) {
                                        localStorage.setItem(
                                            'bidIncrease',
                                            JSON.stringify({
                                                proposed_amount: localStorage.share,
                                                id: localStorage.itemId,
                                                bidding_type: 'hard',
                                                proposed_qty: '',
                                                customBidData: {
                                                    autopurchase: localStorage.autopurchase,
                                                },
                                                autopurchase: localStorage.autopurchase,
                                            }),
                                        )
                                        localStorage.setItem(
                                            'current_price',
                                            responseStatus.data?.ttwRes[0].current_price,
                                        )
                                        localStorage.setItem(
                                            'old_price',
                                            responseStatus.data?.ttwRes[0].old_price,
                                        )
                                    }
                                    handleRedirectInternal(history, `paymentinfo`)
                                } else {
                                    handleRedirectInternal(
                                        history,
                                        `productview/${localStorage.itemId}/${localStorage.itemTitle}`,
                                    )
                                    localStorage.removeItem('ttw_temp_user_id')
                                    localStorage.removeItem('itemId')
                                    localStorage.removeItem('itemTitle')
                                    localStorage.removeItem('share')
                                    localStorage.removeItem('autopurchase')
                                    localStorage.removeItem('current_bid')
                                }
                            } else {
                                handleRedirectInternal(history, 'search?auctions=1')
                            }
                        }
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            {!emailVerify ? (
                <RegistrationLayout>
                    <div className="loginWrapper p-5 h-100">
                        <div className="rdrLnk text-right">
                            Don&apos;t have an account?
                            <Link className="ml-2" to="/register" id="signup_link">
                                Register!
                            </Link>
                        </div>
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <form className="loginForm mb-0" onSubmit={formik.handleSubmit}>
                                <h3 className="mb-3">Login</h3>
                                <div className="row">{Object.values(mapData(loginInfo))}</div>
                                <div className="chkCntnr d-flex align-items-center justify-content-between">
                                    {Object.values(mapData(rememberMe))}
                                    <div className="w-100 text-right">
                                        <Link id="forgot_password_link" to="/forgot_password">
                                            Forgot Password?
                                        </Link>
                                    </div>
                                </div>
                                <PrimaryButton id="login_button" label="login" type="submit" />
                            </form>
                        </div>
                    </div>
                </RegistrationLayout>
            ) : (
                <EmailVerify
                    emailVerify={emailVerify}
                    formik={formik}
                    setEmailVerify={setEmailVerify}
                    changeEmailVerify={changeEmailVerify}
                />
            )}
        </>
    )
}

export default Login
