import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import GridView from '../../custom/components/molecules/ProductCard/GridViewSearch'
import './search.css'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import { Button, Fade, IconButton, ListItem } from '@material-ui/core'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '../../custom/components/organisms/ProductView'
import ProductContext from '../../product/context/product/productContext'
import AuthContext from '../../product/context/auth/authContext'
import AlertContext from '../../product/context/alert/alertContext'
import CommonContext from '../../product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { messageHandler } from '../../product/common/socketHandler'
import { useCustomMediaQuery } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import Loaders from '../../custom/components/molecules/Loaders'
import { handleRedirectInternal } from '../../product/common/components'
import { useHistory, useLocation } from 'react-router'
import { Panorama } from '@material-ui/icons'
import AppLayout from '../../custom/components/templates/AppLayout'
import { useMediaQuery } from '@material-ui/core'

import { dateTimeFormatFunction } from '../../custom/common/components'

import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { mapData } from '../../product/common/components'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const ExternalSearch = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const { setSearchValue, searchValue, clearSearchValue } = commonContext
    const { addCampaignContact, responseStatus: responseStatusCampaign } = useContext(
        NorthCapitalContext,
    )
    const [loading, setLoading] = useState(false)
    const [savedSearchOn, setSavedSearchOn] = useState(false)
    const [savedSearchTemp, setSavedSearchTemp] = useState('')
    const [flrtSt, setFlrSt] = useState('all')
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [isPast, setPastauction] = useState(false)
    const [activeFilter, setActiveFilter] = useState('all_ttw_closed')
    const [isActionTab, setIsAuctionAvailableTab] = useState(false)
    const [isTTWAvailableTab, setIsTTWAvailableTab] = useState(false)
    // const [isOfferingAvailableTab, setIsOfferingAvailableTab] = useState(false)
    const [isUpcomming, setIsUpcommingTab] = useState(false)
    const [isAction, setIsAuctionAvailable] = useState(false)
    const [isLiveOffer, setIsOfferAvailable] = useState(false)
    const [isLiveTTW, setIsTTWAvailable] = useState(false)
    const mobile = useMediaQuery('(max-width:1366px)')
    const [searchPopup, setSearchPopup] = useState(false)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const dummyLimit = searchParams.get('limit') //Adding this just for testing the iframe. It can be removed once the iFrame case is confirmed.

    const classes = useStyles()

    const {
        search_allproducts,
        getAllSearchProducts,
        responseStatus,
        clearResponse,
        addSavedSearch,
    } = productContext
    const { user, isAuthenticated, saveSearch } = authContext

    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [reload, setReload] = useState(false)
    const [viewProduct, setViewProduct] = useState([])
    const [actionType, setAuctionType] = useState('ttw')
    const auctionType = [
        {
            value: 'all',
            show: 'All',
        },
        {
            value: 'live',
            show: 'Regular Auction',
        },
        {
            value: 'ttw',
            show: 'VIP Auction',
        },
        {
            value: 'buynow',
            show: 'Offering',
        },
    ]

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: 'p.date_added, asc',
            show: 'Start time',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time remaining',
        },
        // {
        //     value: 'p.id, desc',
        //     show: 'Sort By',
        // },
        {
            value: 'p.wprice, asc',
            show: 'Current price low to high',
        },
        {
            value: 'p.wprice, desc',
            show: 'Current price high to low',
        },
        {
            value: 1,
            show: 'Ending today',
        },
        {
            value: 'p.bprice, asc',
            show: 'Buy now price low to high',
        },
        {
            value: 'p.bprice, desc',
            show: 'Buy now price high to low',
        },
    ]

    const sortShowPastOffering = [
        {
            value: 'p.date_closed, desc',
            show: 'Newest to Oldest (by End Date)',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Oldest to Newest (by End Date)',
        },
        {
            value: 'p.title, asc',
            show: 'Alphabetical (A to Z)',
        },

        {
            value: 'p.bprice, asc',
            show: 'Price (Low to High by Share Price)',
        },
        {
            value: 'p.bprice, desc',
            show: 'Price (High to Low by Share Price)',
        },
    ]

    // Used to set initail search values
    const [search, setSearch] = useState({
        limit: dummyLimit ? dummyLimit : 8,
        page: 1,
        orderby: 'p.date_added, asc',
        order: '',
        market_status: ['open'],
        isttwComingSoon: true,
        freeShare: 1,
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
            subCategory: {
                value: [],
                type: 'array',
                field: 'it.subCategoryTypeId',
            },
            condition: {
                value: [],
                type: 'array',
                field: 'it.conditionTypeId',
            },
            location: {
                value: [],
                type: 'array',
                field: 'p.location_id',
            },
            price: {
                value: 0,
                type: 'greaterequal',
                field: 'p.wprice',
            },
            auctionid: {
                value: [],
                type: 'array',
                field: 'p.auctionid',
            },
            auctiontype: {
                value: '',
                type: 'in',
                field: 'p.auctiontype',
            },
        },
    })

    const validationArray = [
        Yup.object({
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            email: '',
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            addCampaignContact(values, 'User', true, true)
        },
    })

    const zohoUpdate = [
        {
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12 col-sm-8',
            formik: formik,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (responseStatusCampaign) {
            if (responseStatusCampaign.from === 'addCampaignContact') {
                setAlert('Successfully updated', 'success')
                // window.location.href = 'https://www.songvest.com/registered'
            }
        }
    }, [responseStatusCampaign])
    useEffect(() => {
        if (searchValue) {
            if (
                searchValue.filters.category &&
                searchValue.filters.category.value &&
                searchValue.filters.category.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        category: {
                            ...search.filters.category,
                            value: searchValue.filters.category.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.searchbar &&
                typeof searchValue.filters.searchbar.value != 'undefined'
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        searchbar: {
                            ...search.filters.searchbar,
                            value: searchValue.filters.searchbar.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.subCategory &&
                searchValue.filters.subCategory.value &&
                searchValue.filters.subCategory.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        subCategory: {
                            ...search.filters.subCategory,
                            value: searchValue.filters.subCategory.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.condition &&
                searchValue.filters.condition.value &&
                searchValue.filters.condition.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        condition: {
                            ...search.filters.condition,
                            value: searchValue.filters.condition.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.location &&
                searchValue.filters.location.value &&
                searchValue.filters.location.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        location: {
                            ...search.filters.location,
                            value: searchValue.filters.location.value,
                        },
                    },
                })
            } else if (searchValue.filters.price && searchValue.filters.price.value) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        price: {
                            ...search.filters.price,
                            value: searchValue.filters.price.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.auctionid &&
                searchValue.filters.auctionid.value &&
                searchValue.filters.auctionid.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        auctionid: {
                            ...search.filters.auctionid,
                            value: searchValue.filters.auctionid.value,
                        },
                    },
                })
            } else if (
                searchValue.filters.auctiontype &&
                searchValue.filters.auctiontype.value &&
                searchValue.filters.auctiontype.value.length > 0
            ) {
                setSearch({
                    ...search,
                    filters: {
                        ...search.filters,
                        auctiontype: {
                            ...search.filters.auctiontype,
                            value: searchValue.filters.auctiontype.value,
                        },
                    },
                })
            }

            if (searchValue.market_status && searchValue.market_status.length > 0) {
                setPastauction(searchValue.market_status.indexOf('sold') > -1)
                setSearch({
                    ...search,
                    market_status: searchValue.market_status,
                    filters: {
                        ...search.filters,
                        auctiontype: {
                            ...search.filters.auctiontype,
                            value: searchValue.filters.auctiontype.value,
                        },
                    },
                })
            }
            clearSearchValue()
        }
    }, [searchValue])

    useEffect(() => {
        setIsLoading(true)
        console.log('search api call', search)
        if (search)
            if (searchValue) {
                // if (!search.filters.searchbar.value || search.filters.searchbar.value == '')
                //     search.filters.searchbar.value = savedSearchTemp
                // search.from = 'search'
                // getAllSearchProducts(search)
            } else {
                if (!search.filters.searchbar.value || search.filters.searchbar.value == '')
                    search.filters.searchbar.value = savedSearchTemp
                if (search.filters.auctiontype.value !== '') {
                    search.from = 'search'
                    getAllSearchProducts(search)
                }
            }
    }, [search, searchValue])

    const sendHeightToParent = () => {
        console.log('TEST LOADED', viewProduct)
        const height = document.body.scrollHeight
        console.log('TEST LOADED HEIGHT', height)
        window.parent.postMessage(height, '*')
    }

    useEffect(() => {
        // Send height on load
        sendHeightToParent()

        // Send height on resize
        window.addEventListener('resize', sendHeightToParent)

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', sendHeightToParent)
        }
    }, [])

    useEffect(() => {
        if (viewProduct.length) {
            setTimeout(sendHeightToParent, 5000)
        }
    }, [viewProduct])
    // useEffect(() => {
    //     if (isLiveAuctionCheck && isLiveAuctionCheck.records) {
    //         console.log('isLiveAuctionCheck', isLiveAuctionCheck)
    //         isLiveAuctionCheck.records.map((data) => {
    //             console.log('pppp', data.auctiontype, data.market_status)
    //             if (data.auctiontype === 'live' && data.market_status === 'open')
    //                 setIsAuctionAvailable(true)
    //             else if (data.auctiontype === 'buynow' && data.market_status === 'open')
    //                 setIsOfferAvailable(true)
    //             else if (data.auctiontype === 'ttw' && data.market_status === 'open') {
    //                 setIsTTWAvailable(true)
    //             }

    //             if (data.isAuctionLive > 0) setIsAuctionAvailableTab(true)
    //             if (data.isTTWLive > 0) setIsTTWAvailableTab(true)
    //             if (data.isUpcomming > 0) setIsUpcommingTab(true)
    //             if (
    //                 data.isAuctionLive > 0 &&
    //                 !history.location.search.includes('vipauctions=1') &&
    //                 !history.location.search.includes('comingsoon=1') &&
    //                 !history.location.search.includes('offerings=1') &&
    //                 !history.location.search.includes('pastoffering=1')
    //             ) {
    //                 handleFilter('live'), setActiveFilter('live')
    //             } else if (
    //                 data.isTTWLive > 0 &&
    //                 !history.location.search.includes('comingsoon=1') &&
    //                 !history.location.search.includes('offerings=1') &&
    //                 !history.location.search.includes('pastoffering=1')
    //             ) {
    //                 handleRedirectInternal(
    //                     history,
    //                     `${'search?vipauctions=1'
    //                     }`,
    //                 )
    //                 handleFilter('ttw'), setActiveFilter('ttw')
    //             } else if (
    //                 data.isUpcomming > 0 &&
    //                 !history.location.search.includes('offerings=1') &&
    //                 !history.location.search.includes('pastoffering=1')
    //             ) {
    //                 handleRedirectInternal(
    //                     history,
    //                     `${
    //                        'search?comingsoon=1'
    //                     }`,
    //                 )

    //                 handleFilter('all_ttw_closed'), setActiveFilter('all_ttw_closed')
    //             } else if (!history.location.search.includes('search?offerings=1')) {
    //                 handleRedirectInternal(
    //                     history,
    //                     `${'search?offerings=1'
    //                     }`,
    //                 )
    //                 handleFilter('buynow'), setActiveFilter('buynow')
    //             } else {
    //                 handleRedirectInternal(
    //                     history,
    //                     `${'search?pastoffering=1'
    //                     }`,
    //                 )
    //                 handleFilter('all_closed'), setActiveFilter('all_closed')
    //             }
    //         })

    //         if (isLiveAuctionCheck?.records?.length === 0) {
    //             if (!history.location.search.includes('pastoffering=1')) {
    //                 handleFilter('buynow'), setActiveFilter('buynow')
    //             } else {
    //                 handleFilter('all_buynow'), setActiveFilter('all_closed')
    //             }
    //         }
    //     }
    // }, [isLiveAuctionCheck])

    // Used to change page
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        if (
            search_allproducts &&
            search_allproducts.records &&
            search_allproducts.from === 'search'
        ) {
            console.log('fbhfv', search_allproducts)
            setTimeout(() => {
                setIsLoading(false)
            }, 1500)
            if (search_allproducts.records.length === 0) {
                // if (activeFilter === 'live') setIsAuctionAvailable(false)
                // else if (activeFilter === 'buynow') setIsOfferAvailable(false)
                // else if (activeFilter === 'ttw') setIsTTWAvailable(false)
            }
            setViewProduct(search_allproducts.records.length > 0 ? search_allproducts.records : [])
        }

        return () => {
            clearResponse()
        }
    }, [search_allproducts])

    useEffect(() => {
        if (searchValue?.filters?.searchbar?.value) {
            search.filters.searchbar.value = searchValue.filters.searchbar.value
            setSearch(search)
            setSavedSearchTemp(searchValue.filters.searchbar.value)
            setSavedSearchOn(true)
        }
        setViewProduct([])

        setIsLoading(true)
        return () => {
            setViewProduct([])
        }
    }, [])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    // Used to display items in wishlist on page load
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    // Used to set and handle sockets
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        setSearch({
            ...search,
            limit: 8,
            page: 1,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    ...search.filters.category,
                    value: [],
                },
                subCategory: {
                    ...search.filters.subCategory,
                    value: [],
                },
                condition: {
                    ...search.filters.condition,
                    value: [],
                },
                location: {
                    ...search.filters.location,
                    value: [],
                },
                price: {
                    ...search.filters.price,
                    value: 0,
                },
                searchbar: {
                    ...search.filters.searchbar,
                    value: '',
                },
                auctionid: {
                    ...search.filters.auctionid,
                    value: [],
                },
                auctiontype: {
                    ...search.filters.auctiontype,
                    value: '',
                },
            },
        })
    }

    useEffect(() => {
        if (props.location.state) {
            setSearch({
                ...search,
                filters: {
                    ...search.filters,
                    searchbar: {
                        ...search.filters.searchbar,
                        value: props.location.state,
                    },
                },
            })
        }
    }, [props.location.state])
    const addSavedSearcData = (search_text) => {
        addSavedSearch({ search_text })
    }

    // useEffect(() => {
    //     handleFilter('ttw')
    // }, [])

    const handleFilter = (srchVl) => {
        if (search.isttwComingSoon) delete search.isttwComingSoon
        if (search.filters.isPrivate) delete search.filters.isPrivate
        search.orderby = 'p.date_added, asc'

        search.market_status = ['open']
        srchVl = ['buynow', 'live', 'ttw']
        search.isttwComingSoon = true
        search.filters.isPrivate = {
            type: 'in',
            value: '0',
            field: 'it.isPrivate',
        }

        setSearch({
            ...search,
            filters: {
                ...search.filters,
                auctiontype: {
                    ...search.filters.auctiontype,
                    type: Array.isArray(srchVl) ? 'array' : 'in',
                    value: srchVl !== 'all_closed' ? srchVl : '',
                },
            },
            page: 1,
        })
        setAuctionType(srchVl)
    }

    useEffect(() => {
        console.log(
            'search?.filters?.searchbar?.value',
            search,
            searchValue,
            search?.filters?.searchbar?.value,
            searchValue?.filters?.searchbar?.value,
        )
        if (
            search?.filters?.searchbar?.value != '' &&
            searchValue?.filters?.searchbar?.value != '' &&
            typeof searchValue?.filters?.searchbar?.value != undefined
        ) {
            setLoading(true)
            setTimeout(() => {
                setSearchPopup(true)
                handleFilter('all')
            }, 800)
        }
    }, [search?.filters?.searchbar?.value, searchValue?.filters?.searchbar?.value])

    useEffect(() => {
        // if (history.location.search.includes('vipauctions=1')) {
        //     handleFilter('ttw'), setActiveFilter('ttw')
        // } else if (history.location.search.includes('auctions=1')) {
        //     handleFilter('live'), setActiveFilter('live')
        // } else if (history.location.search.includes('comingsoon=1')) {
        //     handleFilter('all_ttw_closed'), setActiveFilter('all_ttw_closed')
        // } else if (history.location.search.includes('offerings=1')) {
        //     handleFilter('buynow'), setActiveFilter('buynow')
        // } else if (history.location.search.includes('pastoffering=1')) {
        //     handleFilter('all_buynow'), setActiveFilter('all_closed')
        // } else {
        //     handleFilter('all_ttw_closed'), setActiveFilter('all_ttw_closed')
        // }

        setActiveFilter('all')
        handleFilter('all')
    }, [])

    const handlePopupClose = () => {
        setSearchPopup(false)
        handleRedirectInternal(history, '')
    }

    // useEffect(() => {
    //     if (activeFilter === 'ttw') {
    //         handleRedirectInternal(history, 'search?vipauctions=1')
    //     }
    // }, [activeFilter])

    const handleRedirectExternal = (path) => {
        history.push(path)
    }

    return (
        <>
            <div className="srchContainer w-100">
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={5} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <div className="searchCnt w-100">
                            <div className={`cardGrid ${auctionView}`}>
                                {viewProduct.map((data, index) => (
                                    <>
                                        <GridView
                                            data={data}
                                            external={true}
                                            favId={`searchProd_${index}`}
                                            // drawerHandler={toggleDrawer('right', true, data)}
                                            onclick={() =>
                                                window.open(
                                                    `productview/${data.id}/${data.title}`,
                                                    '_blank',
                                                )
                                            }
                                        />
                                    </>
                                ))}
                            </div>
                        </div>
                        {viewProduct.length <= 4 ? (
                            <>
                                {/* <div className="emptyCardImage small">
                                    <img src="/assets/images/emptyBannerSmall.png" alt="" />
                                </div> */}
                                {/* <div className="emptyCard small">
                                <img
                                    src="/assets/svg/logoIcon.svg"
                                    className="logoIcon"
                                    alt="SongVest Logo"
                                />
                                <h6>More Auctions coming soon.</h6>
                            </div> */}
                            </>
                        ) : null}
                    </>
                ) : (
                    <>
                        <div className="emptyCardImage large">
                            <img src="/assets/images/emptyBannerLarge.png" alt="" />
                        </div>
                        {/* <div className="emptyCard large">
                        <img
                            src="/assets/svg/logoIcon.svg"
                            className="logoIcon"
                            alt="SongVest Logo"
                        />
                        <h6>New Auctions would be added shortly.</h6>
                        <div className="eclCards">
                            <img src="/assets/images/emptyCard2.png" />
                            <img src="/assets/images/emptyCard2.png" />
                            <img src="/assets/images/emptyCard2.png" />
                            <img src="/assets/images/emptyCard2.png" />
                        </div>
                    </div> */}
                    </>
                )}
            </div>

            <FullScreenPopup
                className="searchPopup"
                open={searchPopup}
                handleClose={handlePopupClose}
            >
                <div className="searchPopupWrapper srchContainer">
                    <div>
                        {isAuthenticated && (search?.filters?.searchbar?.value || savedSearchOn) && (
                            <div className="srchRsltTxtWrpr d-flex align-items-center justify-content-between w-100">
                                <CustomSelect
                                    name="resultsPerPage"
                                    selectType="noBorder"
                                    value={search.limit}
                                    size="small"
                                    onChange={(event, editor) => {
                                        setSearch({
                                            ...search,
                                            limit: event.target.value,
                                            market_status: ['open'],
                                        })
                                    }}
                                >
                                    {pageOptions.map((opt, optindex) => (
                                        <option value={opt.value}>{opt.show}</option>
                                    ))}
                                </CustomSelect>
                                <div className="leftDiv w-100 text-left d-flex justify-content-center align-items-end">
                                    <small className="txt_one">Search Result for</small>
                                    <h4 className="srchTxt mx-2 mb-0">
                                        {search.filters.searchbar.value || savedSearchTemp}
                                    </h4>
                                    <small className="rsltsCount">
                                        {search_allproducts.totalRecords} results found
                                    </small>
                                </div>
                                <div className="pr-3">
                                    <Button
                                        id="saved_search"
                                        className="svSrchBtn"
                                        onClick={() =>
                                            addSavedSearcData(
                                                search.filters.searchbar.value || savedSearchTemp,
                                            )
                                        }
                                    >
                                        <span className="material-icons">bookmark_add</span>
                                        Save this search
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <IconButton onClick={handlePopupClose} className="searchCloseBtn">
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <div className="searchCnt pt-3 pb-5 w-100">
                        {isLoading ? (
                            <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                        ) : viewProduct.length > 0 ? (
                            <>
                                <div className={`cardGrid ${auctionView}`}>
                                    {viewProduct.map((data, index) => (
                                        <>
                                            <GridView
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                external={true}
                                                // drawerHandler={toggleDrawer('right', true, data)}
                                                onclick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `productview/${data.id}/${data.title}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ))}
                                </div>

                                <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                                    <h6>
                                        {'Showing'} {search_allproducts.setDisp} {'of'}{' '}
                                        {search_allproducts.totalRecords}
                                    </h6>
                                    <Pagination
                                        count={Math.ceil(
                                            search_allproducts.totalRecords / search.limit,
                                        )}
                                        page={search.page}
                                        onChange={onHandlePage}
                                        siblingCount={3}
                                        showFirstButton
                                        showLastButton
                                        boundaryCount={2}
                                    />
                                </div>
                            </>
                        ) : (
                            // <NoRecordsFound />
                            <div className="noRctdFnd text-center">
                                <span className="material-icons">find_in_page</span>
                                <p className="text-center mt-2">
                                    Uh Oh! Nothing&apos;s live today but check our other tabs for
                                    available investments!
                                </p>
                                {!isAuthenticated && (
                                    <>
                                        <hr />
                                        <p className="mb-3">
                                            Make sure you&apos;ve signed up to receive email updates
                                            when new sales are announced!
                                        </p>
                                        <form onSubmit={formik.handleSubmit} className="mt-2 mb-4">
                                            <div className="row zohoUpdWrpr mt-4">
                                                {Object.values(mapData(zohoUpdate))}
                                                <div className="col-md-4">
                                                    <PrimaryButton
                                                        label="Keep Me Updated"
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </FullScreenPopup>
        </>
    )
}
export default ExternalSearch
