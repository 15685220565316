import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useFormik, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import AlertContext from '../../product/context/alert/alertContext'
import Loaders from '../../custom/components/molecules/Loaders'
import { Link } from 'react-router-dom'
import { apiCall } from '../../product/common/api'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
import { FormatLineSpacing } from '@material-ui/icons'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import SettingContext from '../../product/context/seller/setting/settingContext'
import CustomDialog from '../../custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import BuyerContext from '../../product/context/buyer/buyerContext'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import TertiaryButton from '../../custom/components/atoms/TertiaryButton'

let randomVariable = Math.random()

function firstStep(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [popup, setPopup] = useState(false)
    const [show, setShow] = useState(false)
    const [type, setType] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const history = useHistory()
    const { addCampaignContact, responseStatus: responseStatusCampaign } = useContext(
        NorthCapitalContext,
    )
    const buyerContext = useContext(BuyerContext)
    let {
        cancelttwbidTemp,
        responseStatus: responseStatusBid,
        clearResponse: clearResponseStatusBid,
    } = buyerContext
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const settingContext = useContext(SettingContext)
    const { searchTable, searchTableAction, table_data } = settingContext
    const { subscribeProfileToListAfterRegister } = useContext(KlaviyoContext)
    const validationArray = [
        Yup.object({
            first_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            last_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'The number only')
                .min(2, 'Invalid format')
                .max(20, 'Maximum 20 characters')
                .required('Required!'),
            market_opt: Yup.boolean(),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '+1',
            market_opt: 1,
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
            Abandons_Registration: true,
            Type: 'Pre Registration',
            Relationship: ['Pre Registration'],
        },
        validationSchema: validationArray[0],
        onSubmit: (values) => {
            randomVariable = Math.random()
            // if (phoneError) {
            //     setAlert('Invalid mobile number / country code', 'error')
            // } else {
            searchTable(
                {
                    table: 'users',
                    page: 1,
                    limit: 2,
                    filters: {
                        username: {
                            value: values.email,
                            type: 'in',
                            field: 'u.email',
                        },
                    },
                },
                randomVariable,
            )
            setShow(true)
            // }
        },
    })

    useEffect(() => {
        if (localStorage.getItem('utm_source')) {
            formik.setFieldValue('utm_source', localStorage.getItem('utm_source'))
        }
    }, [localStorage.getItem('utm_source')])

    useEffect(() => {
        if (localStorage.getItem('utm_medium')) {
            formik.setFieldValue('utm_medium', localStorage.getItem('utm_medium'))
        }
    }, [localStorage.getItem('utm_medium')])

    useEffect(() => {
        if (localStorage.getItem('utm_campaign')) {
            formik.setFieldValue('utm_campaign', localStorage.getItem('utm_campaign'))
        }
    }, [localStorage.getItem('utm_campaign')])

    useEffect(() => {
        if (table_data.from === randomVariable) {
            if (table_data.records && table_data.records.length) {
                setAlert('Registration Failed! Email Already Exists.', 'error')
            } else {
                searchTable(
                    {
                        table: 'company_owners',
                        page: 1,
                        limit: 2,
                        filters: {
                            username: {
                                value: formik.values.email,
                                type: 'in',
                                field: 'co.email',
                            },
                        },
                    },
                    'checkcompanyowner',
                )
            }
        }
        if (table_data.from === 'checkcompanyowner' && show) {
            if (table_data.records && table_data.records.length) {
                setAlert('Registration Failed! Email Already Exists.', 'error')
            } else {
                formik.values.Accept_Marketing = formik.values.market_opt === 1 ? true : false
                formik.values.Email_Opt_Out = formik.values.market_opt ? false : true
                formik.values.First_Name = formik.values.first_name
                formik.values.Last_Name = formik.values.last_name
                formik.values.Email = formik.values.email
                formik.values.Phone = formik.values.phone
                formik.values.UTM_Source = formik.values.utm_source
                formik.values.UTM_Medium = formik.values.utm_medium
                formik.values.UTM_Campaign = formik.values.utm_campaign

                if (type === 'update') {
                    try {
                        addCampaignContact(formik.values, 'Subscriber', true, false)
                    } catch (err) {
                        console.log('Zoho Contact Err =>', err)
                    }
                    // window.location.href = 'https://www.songvest.com/registered'
                } else {
                    addCampaignContact(formik.values, 'User', true, true)

                    const klaviyoPostData = JSON.parse(JSON.stringify(formik.values))
                    console.log('klaviyoPostData', klaviyoPostData)
                    // subscribeProfileToListAfterRegister(klaviyoPostData, true)

                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                        pageFormSubmission: 'Lead',
                        userEmail: formik.values.email,
                        userFirstName: formik.values.first_name,
                        userLastName: formik.values.last_name,
                    })
                    //
                    // console.log(['Lead', window.dataLayer])
                    props.handleNext()
                    localStorage.setItem(
                        'state',
                        JSON.stringify({
                            first_name: formik.values.first_name,
                            last_name: formik.values.last_name,
                            email: formik.values.email,
                            phone: formik.values.phone,
                            is_marketing_opt: formik.values.market_opt ? 1 : 0,
                            domesticYN: formik.values.domesticYN,
                        }),
                    )
                    // history.push({ pathname: '/registration', state: values })
                }
            }
            setShow(false)
        }
    }, [table_data, show])

    useEffect(() => {
        if (responseStatusCampaign) {
            if (responseStatusCampaign.from === 'addCampaignContact') {
                window.location.href = 'https://www.songvest.com/registered'
            }
        }
    }, [responseStatusCampaign])

    const errorFunc = (data) => {
        console.log(data, 'fghgghg')
        setPhoneError(data)
    }

    const IndividualRegistration = [
        {
            label: 'First Name',
            name: 'first_name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: `col-12 col-sm-6`,
            autoFocus: true,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Last Name',
            name: 'last_name',
            type: 'text',
            placeholder: 'Enter your last name',
            class: `col-12 col-sm-6`,
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Email Address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Mobile Number (Optional)',
            name: 'phone',
            type: 'phone',
            placeholder: 'Mobile number',
            class: 'col-12 col-sm-6',
            formik: formik,
            // onError: errorFunc,
            displayFlag: true,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (responseStatusBid) {
            console.log(responseStatusBid, 'responseStatusBid')
            if (responseStatusBid.from === 'cancelttwbidTemp') {
                if (responseStatusBid.status === 'success') {
                    setAlert(responseStatusBid.message, responseStatusBid.status)
                    setPopup(false)
                    localStorage.removeItem('ttw_temp_user_id')
                    localStorage.removeItem('itemId')
                    localStorage.removeItem('itemTitle')
                    localStorage.removeItem('share')
                    handleRedirectInternal(history, '')
                } else {
                    setAlert(responseStatusBid.message, 'error')
                }
            }
            clearResponseStatusBid()
        }
    }, [responseStatusBid])

    const changeTempDialogStatus = () => {
        setPopup(!popup)
    }

    const checkEmail = () => {}

    const marketCheck = [
        {
            label: '',
            name: 'market_opt',
            type: 'checkbox',
            class: 'd-inline-block',
            formik: formik,
            upperLabel: true,
        },
    ]

    useEffect(() => {
        if (localStorage.ttw_temp_user_id && props.updatePopup) {
            setPopup(true)
        }
    }, [props.updatePopup])

    return (
        <>
            {/* <div className="loginWrapper p-5 h-100"> */}

            <form className="registerForm mb-0" onSubmit={formik.handleSubmit}>
                {/* <h3 className="mb-4">Create Account</h3> */}
                <div className="row mt-4">{mapData(IndividualRegistration)}</div>
                <div className="row">
                    {/* <div className="col-md-6 col-12 regActBox mt-2 preReg pr-3">
                                <div>Just sign me up to keep me updated, I might bid later</div>
                                <PrimaryButton
                                    label="Just Keep Me Updated"
                                    type="submit"
                                    onClick={() => {
                                        setType('update')
                                    }}
                                />
                            </div> */}

                    <div className="col-md-12 mt-5">
                        <p className="markOptCheck">
                            {mapData(marketCheck)}
                            <small>
                                {/* By signing up at SongVest, you agree to receive marketing
                                        emails from us. If you do not want to be contacted for new
                                        offers or sales, please check here to opt out.{' '} */}
                                By clicking here, you agree to receive marketing emails from us.{' '}
                            </small>
                        </p>
                    </div>

                    <div className="col-md-12 regActBox mt-2 preReg text-center">
                        {/* <div>I am ready to finish my registration so that I can bid</div> */}
                        <PrimaryButton
                            id="registerpage_summit_button"
                            label="NEXT"
                            className="w-100"
                            onClick={() => formik.handleSubmit()}
                        />
                        <br />
                        {localStorage.ttw_temp_user_id ? (
                            <Button className="ml-2" onClick={() => setPopup(true)}>
                                Finish Later
                            </Button>
                        ) : (
                            <Button
                                className="ml-2"
                                onClick={() => handleRedirectInternal(history, '')}
                            >
                                Finish Later
                            </Button>
                        )}
                        <CustomDialog
                            title=""
                            open={popup}
                            function={changeTempDialogStatus}
                            disableBackdropClick
                        >
                            <h5>
                                Are you sure you want to cancel your bid for {''}
                                {localStorage.share} shares of {`"${localStorage.itemTitle}"`}
                            </h5>
                            <div className="actionWrapper">
                                <Button
                                    onClick={() => {
                                        setPopup(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                                <PrimaryButton
                                    onClick={() => {
                                        cancelttwbidTemp({
                                            ttw_temp_user_id: localStorage.ttw_temp_user_id,
                                        })
                                    }}
                                    type="button"
                                    label="Yes"
                                />
                            </div>
                        </CustomDialog>
                    </div>
                </div>
            </form>

            {/* </div> */}
        </>
    )
}

export default firstStep
