import React, { useEffect, useContext, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Header from '../custom/components/molecules/Header'
import Footer from '../custom/components/molecules/Footer'
import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import NorthCapitalContext from '../product/context/payment/northcapital/northcapitalContext'
import commonContext from '../product/context/common/commonContext'
import RosoomResponse from '../views/Rosoom/Response'
import Home from '../views/Home'
import Payment from '../views/Payment/Payment'
import Loaders from '../custom/components/molecules/Loaders'
import RegistrationView from '../views/Registration/view'
import Registration from '../views/Registration/preStep'
import RegistrationNew from '../views/Registration/preStepNew'
import RegistrationStart from '../views/Registration'
import Login from '../views/Login'
import RegSteps from '../views/Registration/RegSteps'
import moreRegSteps from '../views/Registration/moreStep'
import Reupdate from '../views/Registration/preUserUpdate'
import Plaid from '../views/Plaid'
import PaymentInfo from '../views/Dashboard/PaymentInfo'
import Preview from '../views/PublicProfile/Preview'
import PurchaseShare from '../views/PurchaseShare/index'
import ArtistView from '../views/ArtistView'
import ExternalSearch from '../views/Search/externalSearch'

// const Login = React.lazy(() => import('../views/Login'))
const ForgotPassword = React.lazy(() => import('../views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/ResetPassword'))
const Search = React.lazy(() => import('../views/Search'))
const HomeRedirect = React.lazy(() => import('../views/Login/home'))
const SwitchUser = React.lazy(() => import('../views/SwitchUser'))
const MyAuctions = React.lazy(() => import('../views/Dashboard/MyAuctions'))
const MyBids = React.lazy(() => import('../views/Dashboard/MyBids'))
// const RegistrationView = React.lazy(() => import('../views/Registration/view'))
// const Registration = React.lazy(() => import('../views/Registration'))
const Profile = React.lazy(() => import('../views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('../views/Dashboard/Profile/ChangePassword'))

const MyInvoices = React.lazy(() => import('../views/Dashboard/MyInvoices'))
const Invoice = React.lazy(() => import('../views/Invoice'))
const LiveAuction = React.lazy(() => import('../views/ProductView/LiveAuction'))
const ProductViewInvite = React.lazy(() => import('../views/ProductView/ProductView_Invite'))
const Cart = React.lazy(() => import('../views/Cart'))
const BuynowCart = React.lazy(() => import('../views/BuyNowCart'))
const Checkout = React.lazy(() => import('../views/Checkout'))

const MyWallet = React.lazy(() => import('../views/Dashboard/MyWallet'))
const ErrorPage = React.lazy(() => import('../views/ErrorPage'))
const Auction = React.lazy(() => import('../views/Auction'))
const Notifications = React.lazy(() => import('../views/Dashboard/Notifications'))
const ContactUs = React.lazy(() => import('../views/ContactUs'))
const StaticPage = React.lazy(() => import('../views/Static'))
const SavedSearch = React.lazy(() => import('../views/Dashboard/SavedSearch'))

const ProductView = React.lazy(() => import('../custom/components/organisms/ProductView'))
const MainDashboard = React.lazy(() => import('../views/Dashboard/MainDashboard'))
const MyTransactions = React.lazy(() => import('../views/Dashboard/MyTransactions'))
const Suitability = React.lazy(() => import('../views/NorthCapital/suitability'))
const VerifyEmail = React.lazy(() => import('../views/VerifyEmail'))
const BidLimit = React.lazy(() => import('../views/BidLimit'))
const VerifyAutoPurchase = React.lazy(() => import('../views/verifyAutoPurchase'))
const AllTradings = React.lazy(() => import('../views/SecondaryTrading'))
const Artists = React.lazy(() => import('../views/Artists'))
// const RegSteps = React.lazy(() => import('../views/Registration/RegSteps'))
const PrivateSales = React.lazy(() => import('../views/PrivateSales'))
const secondaryTradingProductView = React.lazy(() =>
    import('../custom/components/organisms/secondaryTradingProductView'),
)

const Routes = (props) => {
    const authContext = useContext(AuthContext)
    const { loadUser, isAuthenticated, callEnd } = authContext
    const customCommonContext = useContext(CustomCommonContext)
    const { getGlobalVariable } = customCommonContext
    const { loaderSet, getAllCountries, getAllStates } = useContext(commonContext)
    const { getUserDetails, getExternalAccount } = useContext(NorthCapitalContext)

    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser()
            getUserDetails()
            getExternalAccount()
        } else {
            loaderSet(false)
        }

        getGlobalVariable()
        getAllCountries()
        getAllStates()
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    useEffect(() => {
        const htmlRoot = document.querySelector('html')
        if (window.location.pathname === '/externalSearch') {
            htmlRoot.classList.add('externalSearch')
        } else {
            htmlRoot.classList.remove('externalSearch')
        }
    }, [window.location.pathname])

    return (
        <>
            {/* {props.location.pathname == '/login' ||
                props.location.pathname == '/register' ||
                props.location.pathname == '/forgot_password' ||
                props.location.pathname == '/register/individual' ||
                props.location.pathname == '/register/entity' ||
                props.location.pathname == '/suitability' ||
                props.location.pathname.includes('/registration') ||
                props.location.pathname.includes('/reset_password') || <Header />} */}
            <Suspense fallback={<Loaders isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={HomeRedirect} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    {/* <Route exact path="/register" component={RegistrationView} /> */}
                    <Route exact path="/register" component={RegistrationNew} />
                    {/* <Route exact path="/registerNew" component={RegistrationNew} /> */}
                    <Route exact path="/search" component={Search} />
                    <Route exact path="/externalSearch" component={ExternalSearch} />
                    {/* <Route exact path="/search/:type" component={Search} /> */}
                    <Route exact path="/liveAuction" component={LiveAuction} />

                    <Route exact path="/artists" component={Artists} />
                    <Route exact path="/artist/:id" component={ArtistView} />
                    <Route exact path="/plaid" component={Plaid} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/productview/:id/:title/:qty" component={ProductView} />
                    <Route exact path="/productview/:id/:title" component={ProductView} />

                    <Route exact path="/contact" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/buynowcart" component={BuynowCart} />
                    <Route exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    <PrivateRoute exact path="/dashboard" component={MainDashboard} />
                    <PrivateRoute exact path="/openBids/:type" component={MyBids} />

                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    {/* <PrivateRoute exact path="/invoices/:action" component={MyInvoices} /> */}
                    <PrivateRoute exact path="/paymentinfo" component={PaymentInfo} />
                    <PrivateRoute exact path="/invoices" component={MyInvoices} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />
                    <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                    {/* <PrivateRoute exact path="/payment/:type" component={MyTransactions} /> */}
                    <PrivateRoute exact path="/suitability" component={Suitability} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <Route exact path="/bidLimitRequest/:token" component={BidLimit} />
                    <Route
                        exact
                        path="/autopurchase_optout/:email/:pid"
                        component={VerifyAutoPurchase}
                    />
                    <Route exact path="/registration/Addpayment" component={RegSteps} />
                    <Route exact path="/registration/uploadDocument/:id" component={moreRegSteps} />
                    <Route exact path="/registration/reupdate" component={Reupdate} />
                    <Route exact path="/registration" component={RegistrationNew} />
                    <Route
                        exact
                        path={[
                            '/about',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms_of_use',
                            '/dwolla_terms',
                        ]}
                        component={StaticPage}
                    />
                    <Route exact path="/secondarytrading/alltradings" component={AllTradings} />
                    <Route exact path="/purchaseshare/:pid/:qty" component={PurchaseShare} />
                    <Route exact path="/secondarytrading/myportfolio" component={AllTradings} />
                    <Route
                        exact
                        path="/secondary-trading/:id/:title"
                        component={secondaryTradingProductView}
                    />
                    <Route exact path="/switch" component={SwitchUser} />
                    <Route exact path="/private_sales" component={PrivateSales} />
                    <Route exact path="/recordlabel/:user_name" component={Preview} />
                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                {console.log(props.location?.search, 'props.location')}
                {props.location.pathname == '/login' ||
                    props.location.pathname == '/register' ||
                    props.location.pathname == '/forgot_password' ||
                    props.location.pathname == '/register/individual' ||
                    props.location.pathname == '/register/entity' ||
                    props.location.pathname == '/suitability' ||
                    props.location.pathname == '/switch' ||
                    props.location.pathname == '/externalSearch' ||
                    props.location.pathname.includes('/registration') ||
                    props.location.pathname.includes('/registerNew') ||
                    props.location.pathname.includes('/reset_password') || <Footer />}
            </Suspense>
        </>
    )
}
export default withRouter(Routes)
