import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useFormik, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import Loaders from '../../custom/components/molecules/Loaders'
import { Link } from 'react-router-dom'
import { apiCall } from '../../product/common/api'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
import { FormatLineSpacing } from '@material-ui/icons'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import PreStep from './firstStep'
import Record from './record'
import Account from './account'
import FinalStep from './finalStep'
import { v4 as uuidv4 } from 'uuid'

function getSteps() {
    return ['Create Account', 'My Record Label', 'Account Type', 'Finish']
}

function preStepNew() {
    const [isLoading, setIsLoading] = useState(false)
    const [type, setType] = useState('')
    const history = useHistory()
    const { addCampaignContact, responseStatus: responseStatusCampaign } = useContext(
        NorthCapitalContext,
    )
    const { subscribeProfileToListAfterRegister } = useContext(KlaviyoContext)
    const [activeStep, setActiveStep] = useState(0)
    const [open, setOpen] = useState(false)
    const steps = getSteps()
    const [popup, setPopup] = useState(false)
    const [regValues, setRegValues] = useState({})

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    const handleSkip = () => {
        if (localStorage.getItem('neverShow')) {
            // setActiveStep((prevActiveStep) => prevActiveStep + 1)
            handleRedirectInternal(history, 'register/survey/buyer')
        } else {
            setOpen(true)
        }
    }

    const registerValues = (data) => {
        setRegValues(data)
    }

    const cancelTemp = () => {
        const id = uuidv4()
        setPopup(id)
    }

    return (
        <RegistrationLayout cancelTemp={cancelTemp}>
            <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />
            <div className="loginWrapper p-5 h-100">
                {/* <div className="rdrLnk text-right">
                    Already have an account?
                    <Link className="ml-2" id="signin_link" to="/login">
                        Login!
                    </Link>
                </div> */}
                <form className="registerForm mb-0 row">
                    <h3 className="mb-4">
                        {activeStep == 0
                            ? 'Create account'
                            : activeStep == 1
                            ? 'Name your record Label'
                            : activeStep == 2
                            ? 'Confirm account type'
                            : 'Contact address'}
                    </h3>
                </form>
                <div className="rgLyrWrpr p-3 d-flex align-items-center justify-content-center">
                    <div className="vpStepper">
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <h6>All steps completed</h6>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        {activeStep == 0 ? (
                                            <PreStep
                                                handleNext={handleNext}
                                                handleBack={handleBack}
                                                handleSkip={handleSkip}
                                                updatePopup={popup}
                                            />
                                        ) : activeStep == 1 ? (
                                            <Record
                                                handleNext={handleNext}
                                                handleBack={handleBack}
                                                handleSkip={handleSkip}
                                            />
                                        ) : activeStep == 2 ? (
                                            <Account
                                                handleNext={handleNext}
                                                handleBack={handleBack}
                                                handleSkip={handleSkip}
                                                registerValues={registerValues}
                                            />
                                        ) : activeStep == 3 ? (
                                            <FinalStep
                                                handleNext={handleNext}
                                                handleBack={handleBack}
                                                handleSkip={handleSkip}
                                                regValues={regValues}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </RegistrationLayout>
    )
}

export default preStepNew
