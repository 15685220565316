import React from 'react'
import { Link } from 'react-router-dom'
import { LOGO, LOGO_WHITE } from '../../../../Utils'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import './RegistrationLayout.css'
const RegistrationLayout = (props) => {
    const location = useLocation()
    return (
        <div className="logRegLayout">
            <div className="leftImgContainer">
                <div className="logoContainer">
                    {/* <img className="whtBackDrop" src="/assets/svg/whitevector.svg" /> */}
                    {location.pathname == '/register' || location.pathname == '/registration' ? (
                        localStorage.ttw_temp_user_id ? (
                            <a onClick={() => props.cancelTemp()}>
                                <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                            </a>
                        ) : (
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}`}>
                                <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                            </a>
                        )
                    ) : location.pathname == '/login' ? (
                        <a href={`${process.env.REACT_APP_FRONTEND_URL}`}>
                            <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                        </a>
                    ) : (
                        <a href="https://songvest.com">
                            <img src={LOGO_WHITE} className="mnLogo cursorDecoy" />
                        </a>
                    )}
                </div>
                <img className="vrtclogo" src="/assets/images/vertical_logos.png" />
            </div>
            <div className="rtDtlContainer w-100">{props.children}</div>
        </div>
    )
}

export default RegistrationLayout
