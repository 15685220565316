import React, { useContext, useEffect, useState } from 'react'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
// import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { LOGO } from '../../Utils'
import { parse, isDate } from 'date-fns'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import Tooltip from '@material-ui/core/Tooltip'
import { Button } from '@material-ui/core'
import CustomDialog from '../../custom/components/organisms/Dialog'

import NorthCapitalCardDetails from '../../custom/components/molecules/NorthCapitalCardDetails'
import NorthCapitalPlaidDetails from '../../custom/components/molecules/NorthCapitalPlaidDetails'
import NorthCapitalBankDetails from '../../custom/components/molecules/NorthCapitalBankDetails'
import Loaders from '../../custom/components/molecules/Loaders'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation } from 'react-router-dom'

const RegSteps = (props) => {
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const [userType, setUserType] = useState(props.match.params.type === 'entity' ? 1 : 0)
    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { user, loadUser } = authContext
    const mobileQuery = useMediaQuery('(max-width:800px)')

    const { updateProfile } = useContext(UserContext)

    const {
        updateIndividual,
        uploadPartyDocument,
        uploadEntityDocument,
        northCapitalUser,
        getUserDetails,
        responseStatus: responseStatusNorthCapitalsMoreStep,
    } = useContext(NorthCapitalContext)

    const [stepCount, setStepCount] = useState(props.match.params.id || 0)
    const [isLoading, setIsLoading] = useState(false)
    const [alertPopup, setalertPopup] = useState(false)

    const queryParam = useLocation().search
    let backTo = new URLSearchParams(queryParam).get('backTo')

    useEffect(() => {
        setStepCount(props.match.params.id)
    }, [props.match.params.id])

    function clickNext() {
        if (stepCount < 4) {
            let step = stepCount + 1
            setStepCount(step)
            updateUser(step)
        }
    }

    function clickPrev() {
        if (stepCount > 0) setStepCount(stepCount - 1)
    }

    useEffect(async () => {
        if (responseStatusNorthCapitalsMoreStep) {
            if (responseStatusNorthCapitalsMoreStep.from === 'uploadPartyDocument') {
                if (responseStatusNorthCapitalsMoreStep.status === 'success') {
                    await getUserDetails()
                    if (history.location.search.includes('isupload=1')) {
                        handleRedirectInternal(history, 'account/profile')
                    } else {
                        handleRedirectInternal(history, '')
                    }
                }
            }

            if (responseStatusNorthCapitalsMoreStep.from === 'uploadEntityDocument') {
                if (responseStatusNorthCapitalsMoreStep.status === 'success') {
                    await getUserDetails()
                    if (history.location.search.includes('isupload=1')) {
                        handleRedirectInternal(history, 'account/profile')
                    } else {
                        handleRedirectInternal(history, '')
                    }
                }
            }
        }
    }, [responseStatusNorthCapitalsMoreStep])

    const folderName = 'northcapital'

    const validationDoc = Yup.object({
        documentTitle: Yup.string(),
        userfile: Yup.array().required('Required!'),
    })

    const validationSSN = Yup.object({
        socialSecurityNumber: Yup.string().required('Required!'),
    })

    const documentFormik = useFormik({
        initialValues: {
            documentTitle: '',
            userfile: [],
            attachFolder: folderName,
        },
        validationSchema: validationDoc,
        onSubmit: (values) => {
            uploadPartyDocument(values)
        },
    })

    const validationEnDoc = Yup.object({
        userfile0: Yup.array().required('Required!'),
        userfile1: Yup.array().required('Required!'),
        userfile2: Yup.array().required('Required!'),
        userfile3: Yup.array().required('Required!'),
    })

    const entityDocumentFormik = useFormik({
        initialValues: {
            documentTitle: '',
            userfile0: [],
            userfile1: [],
            userfile2: [],
            userfile3: [],
            attachFolder: folderName,
        },
        validationSchema: validationEnDoc,
        onSubmit: (values) => {
            let entityName = user.entityName ? user.entityName : 'NewEntity'
            let documents = []
            for (let i = 0; i < 4; i++) {
                let obj = {
                    documentTitle: entityDocumentDetails.data[i].documentName
                        ? entityDocumentDetails.data[i].documentName + ' Documents'
                        : entityName + ' Documents',
                    userfile: values[`userfile${i}`],
                    attachFolder: folderName,
                }
                documents.push(obj)
            }
            uploadEntityDocument({ documents })
        },
    })

    useEffect(() => {
        if (northCapitalUser) {
            if (!history.location.search.includes('isupload=1')) {
                if (northCapitalUser.kyc_review) {
                    handleRedirectInternal(history, 'search')
                }
            }
        }
    }, [])

    useEffect(() => {
        if (user) {
            let firstName = user.first_name ? user.first_name : ''
            let lastName = user.last_name ? user.last_name : ''
            documentFormik.setFieldValue('documentTitle', firstName + ' ' + lastName + ' Documents')
        }
    }, [user])

    const documentDetails = {
        formik: documentFormik,
        data: [
            {
                type: 'uploadDropZone',
                name: 'userfile',
                titleText:
                    user && user.domicile !== 'U.S. Citizen'
                        ? 'Upload PDF or JPG: Valid Passport'
                        : 'Upload PDF or JPG: Valid Driver’s License',
                innerText: '',
                class: 'col-12  px-0 mt-4',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
            },
        ],
    }
    const entityDocumentDetails = {
        formik: entityDocumentFormik,
        data: [
            {
                type: 'uploadDropZone',
                name: 'userfile0',
                titleText:
                    'Upload PDF or JPG: Articles of Incorporation or LLC Operating Agreement',
                innerText: '',
                class: 'col-12  px-0 mt-4',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
                documentName: 'Articles of Incorporation or LLC Operating Agreement',
            },
            {
                type: 'uploadDropZone',
                name: 'userfile1',
                titleText: 'Upload PDF or JPG: Tax ID Letter (IRS EIN Paperwork)',
                innerText: '',
                class: 'col-12  px-0 mt-4',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
                documentName: 'Tax ID Letter',
            },
            {
                type: 'uploadDropZone',
                name: 'userfile2',
                titleText: 'Upload PDF or JPG: Valid Photo ID of Officer',
                innerText: '',
                class: 'col-12  px-0 mt-4',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
                documentName: 'Photo ID of Officer',
            },
            {
                type: 'uploadDropZone',
                name: 'userfile3',
                titleText: 'Upload PDF or JPG: State Incorporation/Certification Document',
                innerText: '',
                class: 'col-12  px-0 mt-4',
                folder: folderName,
                multiple: false,
                accept: 'image/*, application/*',
                documentName: 'State Incorporation/Certification',
            },
        ],
    }
    const stepFormik = useFormik({
        initialValues: {
            alreadyCompleted: '',
            securityNumber: '',
            maxbid_requested: false,
        },
        onSubmit: (values) => {},
    })

    const ssnFormik = useFormik({
        initialValues: {
            socialSecurityNumber: '',
            updateKyc: true,
        },
        validationSchema: validationSSN,
        onSubmit: (values) => {
            updateIndividual(values)
        },
    })

    const handleDocumentLater = async () => {
        setalertPopup(false)
        setIsLoading(true)
        await updateProfile(
            {
                is_document_later: 1,
            },
            true,
        )
        await loadUser()
        setIsLoading(false)
        handleRedirectInternal(history, 'search')
    }

    // useEffect(() => {
    //     if (user) {
    //         if (user.entityType && stepCount === '1') {
    //             handleRedirectInternal(history, 'search')
    //         }
    //     }
    // }, [user])

    const ssnDetails = {
        formik: ssnFormik,
        data: [
            {
                label: 'SSN Number',
                type: 'mask',
                maskValue: '999-99-9999',
                placeholder: 'Enter ssn number',
                class: 'col-md-6 col-12 px-0 mt-4',
                name: 'socialSecurityNumber',
            },
        ],
    }

    function stepSwitch(stepCount) {
        switch (stepCount) {
            case 0:
                return (
                    <>
                        <p className="text-left">SSN has failed.</p>
                        <p className="text-left">Update the SSN Number.</p>
                        {mapData(ssnDetails)}
                    </>
                )
            case 1:
                return (
                    <>
                        {user && user.user_type === 0 ? (
                            user.domicile !== 'U.S. Citizen' ? (
                                <>
                                    <p className="text-left">
                                        Due to strict SEC and broker-dealer requirements, we must
                                        verify the identity of all users. We need a bit more
                                        information to open your account. Please upload a PDF or JPG
                                        of a valid ID to verify your identity.
                                    </p>
                                    <p className="text-left">
                                        We will review the document and let you know if we need any
                                        other information. You will be unable to bid or buy
                                        SongShares until approved.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className="text-left">
                                        Due to strict SEC and broker-dealer requirements, we must
                                        verify the identity of all users. We need a bit more
                                        information to open your account. Please upload a PDF or JPG
                                        of a valid ID to verify your identity.
                                    </p>
                                    <p className="text-left">
                                        We will review the document and let you know if we need any
                                        other information. You will be unable to bid or buy
                                        SongShares until approved.
                                    </p>
                                </>
                            )
                        ) : (
                            <>
                                <p className="text-left">
                                    Due to strict SEC and broker-dealer requirements, we must verify
                                    the identity of all users. We need a bit more information to
                                    open your account. Please upload a PDF or JPG of a valid ID to
                                    verify your identity.
                                </p>
                                <p className="text-left">
                                    We will review the document and let you know if we need any
                                    other information. You will be unable to bid or buy SongShares
                                    until approved.
                                </p>
                            </>
                        )}
                        {user && user.user_type === 1 ? mapData(entityDocumentDetails) : ''}
                        {user && user.user_type !== 1 ? mapData(documentDetails) : ''}
                    </>
                )
            default:
                return 'unknown'
        }
    }

    return (
        <RegistrationLayout>
            <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />

            <div className="loginWrapper p-5 h-100">
                {stepSwitch(Number(stepCount))}
                <div className="btmNavigation">
                    {typeof backTo !== 'undefined' && backTo !== null && backTo == 'settings' ? (
                        <>
                            <Button
                                className="navBtn mr-auto"
                                onClick={() => handleRedirectInternal(history, 'account/profile')}
                            >
                                Go Back To Settings
                                <span className="material-icons ml-1 finishBtnIcon">
                                    watch_later
                                </span>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button className="navBtn mr-auto" onClick={() => setalertPopup(true)}>
                                Upload Document later
                                <span className="material-icons ml-1 finishBtnIcon">
                                    watch_later
                                </span>
                            </Button>
                        </>
                    )}

                    {Number(stepCount) === 1 ? (
                        <Button
                            className="navBtn ml-auto  ml-auto"
                            onClick={() =>
                                user && user.user_type === 1
                                    ? entityDocumentFormik.handleSubmit()
                                    : documentFormik.handleSubmit()
                            }
                        >
                            Finish
                            <span className="material-icons ml-1 finishBtnIcon">task_alt</span>
                        </Button>
                    ) : (
                        <Button className="navBtn ml-auto" onClick={() => ssnFormik.handleSubmit()}>
                            Finish
                            <span className="material-icons ml-1 finishBtnIcon">task_alt</span>
                        </Button>
                    )}
                </div>
                <p className="disclaimerTxt text-left mt-3">
                    <small>
                        All securities-related activity is conducted through Dlamore Group, LLC
                        (&quot;Dalmore&quot;), a registered broker-dealer and member FINRA/SIPC.
                        Dalmore does not make recommendations and acts only as the broker/dealer of
                        record for the offering listed. You can review the background of our
                        broker-dealer and our investment professionals on FINRA&apos;s BrokerCheck.{' '}
                        {mobileQuery && <br />}
                        <a href="https://brokercheck.finra.org/firm/summary/136352">
                            https://brokercheck.finra.org/firm/summary/136352
                        </a>
                    </small>
                </p>
            </div>
            <CustomDialog
                open={alertPopup}
                size="sm"
                title="Upload Document later"
                function={() => setalertPopup(false)}
            >
                <p className="alertContent mb-4">
                    You will not be able to bid or purchase units until you upload a document to
                    verify your identity. You can go into your settings to upload this document when
                    ready.
                </p>
                <div className="actionWrapper text-center alertActionCont">
                    <PrimaryButton onClick={handleDocumentLater} type="button" label="Okay" />
                </div>
            </CustomDialog>
        </RegistrationLayout>
    )
}

export default RegSteps
