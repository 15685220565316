import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import './registration.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { mapData, calculateAge, handleRedirectInternal } from '../../product/common/components'
// import PhoneValidation from './phoneValidation'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../product/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import AuthContext from '../../product/context/auth/authContext'
import CommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'
import { FilterFramesOutlined } from '@material-ui/icons'
import { Document } from 'react-pdf'
import { LOGO } from '../../Utils'
import { parse, isDate } from 'date-fns'
import StripeCustomerContext from '../../product/context/stripe/customer/customerContext'
import NorthCapitalContext from '../../product/context/payment/northcapital/northcapitalContext'
import KlaviyoContext from '../../custom/context/klaviyo/klaviyoContext'
import AlertContext from '../../product/context/alert/alertContext'
import SettingContext from '../../product/context/seller/setting/settingContext'

import Tooltip from '@material-ui/core/Tooltip'
import Loaders from '../../custom/components/molecules/Loaders'
import CustomPopover from '../../custom/components/atoms/Popover'
import { el } from 'date-fns/locale'
import InitialPopup from './InitialPopup'
import Cryptos from '../../product/common/cryptos'

let randomVariable = Math.random()

const FinalStep = (props) => {
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isSSN, setSSN] = useState(true)
    const [userType, setUserType] = useState(0)
    const [type, setType] = useState('')
    const [registerUser, setRegisteredUser] = useState(null)
    const [businessClassificationsList, setBusinessClassifications] = useState([])
    const [UserId, setUserId] = useState(null)
    const [currentCountry, setCurrentCountry] = useState()
    const [initialPopup, setInititalPopup] = useState(false)
    const {
        register,
        responseStatus,
        clearResponse,
        loadUser,
        user,
        cartTempCount,
        cartCount,
    } = authContext
    const [reload, setReload] = useState(false)

    const commonContext = useContext(CommonContext)
    const settingContext = useContext(SettingContext)
    const productCommonContext = useContext(ProductCommonContext)
    const { updateProfile, responseStatus: responseStatusUser } = useContext(UserContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const {
        configFeatures,
        createCustomer,
        dwollaCustomerResponse,
        businessClassifications,
        dwollabusinessClassifications,
    } = commonContext
    const { searchTable, searchTableAction, table_data } = settingContext
    const { static_page, getStaticPage, allCountries, allStates } = productCommonContext
    const { responseStatus: responseStatusStripeCustomer, createStripeCustomer } = useContext(
        StripeCustomerContext,
    )
    const {
        onBoardEntity,
        onBoardIndividual,
        addCampaignContact,
        responseStatus: responseStatusNorthCapital,
    } = useContext(NorthCapitalContext)
    const { updateProfileAfterRegistration, subscribeProfileToListAfterRegister2 } = useContext(
        KlaviyoContext,
    )
    let [termsLink, setTermsLink] = useState([])
    let [submitCount, setSubmitCount] = useState('')
    let [zohoRecord, setZohoRecord] = useState({
        email: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        zoho_id: null,
        created_at: null,
    })
    const [currentRegistrationType, setCurrentRegistrationType] = useState(false)
    const [inType, setInType] = useState('SSN')
    const [initialValues, setInitialValues] = useState({})

    const newRegistration = true

    // const [phoneVerify, setPhoneVerify] = useState(false)
    // const [disable, setDisable] = useState(false)

    // const changePhoneVerify = () => {
    //     setPhoneVerify(!phoneVerify)
    // }

    // YUP Validation array to ensure all required fields are given values
    const validationArray = [
        Yup.object({
            address1: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            address2: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 characters'),

            country: Yup.string().trim().required('Required!'),
            city: Yup.string()
                .trim()
                //.matches(/^[0-9a-zA-Z ]*$/g, 'The characters only')
                .matches(/^[a-zA-Z ]*$/g, 'The characters only')
                .required('Required!'),
            //state: Yup.string().trim().required('Required!'),
            state: Yup.string().when(['country'], {
                is: (val) => val == 'US' || val == 'CA',
                then: Yup.string().trim().required('Required!'),
                otherwise: Yup.string().trim(),
            }),
            zip: Yup.string()
                .trim()
                .min(3, 'Minimum 3 characters Required!')
                .max(7, 'Maximum 7 characters Required!')
                .required('Required!'),

            termCheck: Yup.boolean()
                .oneOf([true], 'Please accept terms and condition')
                .required('Please accept terms and condition'),
        }),
    ]
    function createStripeUser(userID) {
        if (userType === 1) {
            let entityDesc =
                businessClassificationsList.length > 0
                    ? businessClassificationsList.filter(
                          (data) => data.id === formik.values.entityDesc,
                      )[0]['name']
                    : formik.values.entityDesc
            onBoardEntity(
                {
                    domicile: formik.values.domicile,
                    domesticYN: formik.values.domesticYN,
                    entityName: formik.values.companyname,
                    entityType: formik.values.entityType,
                    entityDesc: entityDesc,
                    ein: formik.values.socialsecurity ? formik.values.socialsecurity : '',
                    primCountry: formik.values.country,
                    primAddress1: formik.values.address1,
                    primAddress2: formik.values.address2,
                    primCity: formik.values.city,
                    primState:
                        formik.values.domesticYN === 'international_account' &&
                        formik.values.domicile == 'non-resident'
                            ? 'NOUS'
                            : formik.values.state,
                    primZip: formik.values.zip,
                    emailAddress: formik.values.email,
                    phone: formik.values.phone,
                    user_id: userID,
                    noAmlCheck: newRegistration ? true : false,
                },
                true,
            )
        } else {
            onBoardIndividual(
                {
                    domicile: formik.values.domicile,
                    domesticYN: formik.values.domesticYN,
                    firstName: formik.values.first_name,
                    lastName: formik.values.last_name,
                    socialSecurityNumber: formik.values.socialsecurity
                        ? formik.values.socialsecurity
                        : '',
                    dob: formik.values.dob,
                    primCountry: formik.values.country,
                    primAddress1: formik.values.address1,
                    primAddress2: formik.values.address2,
                    primCity: formik.values.city,
                    primState:
                        formik.values.domesticYN === 'international_account' &&
                        formik.values.domicile == 'non-resident'
                            ? 'NOUS'
                            : formik.values.state,
                    primZip: formik.values.zip,
                    emailAddress: formik.values.email,
                    phone: formik.values.phone,
                    user_id: userID,
                },
                true,
            )
        }

        // setRegisteredUser(userID)
        // createStripeCustomer({
        //     account_id: 'acct_1Iq9B5PG1bvmzFNY',
        //     description: 'TEST CUSTOMER',
        //     email: formik.values.email,
        //     name:
        //         userType === 1
        //             ? formik.values.companyname
        //             : formik.values.first_name + ' ' + formik.values.last_name,
        //     address: {
        //         line1: '637, Cambridge Circle',
        //         line2: 'Richardson',
        //         city: 'Richardson',
        //         postal_code: '75060',
        //         state: 'Texas',
        //         country: 'US',
        //     },
        // })
    }

    useEffect(() => {
        if (responseStatusNorthCapital) {
            if (
                responseStatusNorthCapital.from === 'onBoardIndividual' ||
                responseStatusNorthCapital.from === 'onBoardEntity'
            ) {
                if (responseStatusNorthCapital.status === 'success') {
                    if (window.fbq) {
                        window.fbq('trackCustom', 'Register', {
                            email: formik.values.email,
                            phone: formik.values.phone,
                        })
                    }
                    /*if (newRegistration) {*/
                    //setInititalPopup(true)

                    let postDataSkip = {
                        kyc_request_count: 1,
                        reg_step_count: 3,
                        reg_steps_skip: '1,2,3,4',
                        is_document_later: 1, //user && user.user_type == 0 && user.domesticYN === 'domestic_account' ? 1 : 0,
                        skipReg: 2, //browse products
                    }

                    updateProfile(postDataSkip, true)

                    /*} else {
                    handleRedirectInternal(history, 'registration/Addpayment')
                    /*}*/
                    //handleRedirectInternal(history, 'registration/Addpayment')
                    // handleRedirectInternal(history, 'login')
                } else {
                    setIsLoading(false)
                }
            }
        }
    }, [responseStatusNorthCapital])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    localStorage.removeItem('userID')
                    setTimeout(() => {
                        if (localStorage.temp_user_id && localStorage.cartTempCount) {
                            handleRedirectInternal(history, 'paymentinfo')
                        } else if (localStorage.ttw_temp_user_id) {
                            console.log('Payment', localStorage.getItem('bidIncrease'))
                            setAlert('Please add card/bank details to reserve shares', 'error')
                            console.log(responseStatusUser?.data, 'responseStatusUser?.data?')
                            handleRedirectInternal(history, `paymentinfo`)
                        } else {
                            handleRedirectInternal(history, 'search?welcome=1')
                        }
                    }, 1000)
                }
            }
        }
    }, [responseStatusUser])

    // useEffect(() => {
    //     if (responseStatusStripeCustomer) {
    //         if (responseStatusStripeCustomer.from === 'create') {
    //             if (responseStatusStripeCustomer.status === 'success') {
    //                 updateProfile(
    //                     {
    //                         authenticate: true,
    //                         user_id: registerUser,
    //                         stripe_id: responseStatusStripeCustomer.data.id,
    //                     },
    //                     true,
    //                 )
    //                 handleRedirectInternal(history, 'login')
    //             }
    //         }
    //     }
    // }, [responseStatusStripeCustomer])

    const createDwollaCustomer = (id) => {
        createCustomer({
            userType: userType,
            user_id: id,
        })
    }

    // Check response status is succesuful

    useEffect(() => {
        if (dwollaCustomerResponse && Object.keys(dwollaCustomerResponse).length > 0 && UserId) {
            loadUser()
            createStripeUser(UserId)
            setIsLoading(false)
        }
        // else {
        //     setIsLoading(false)
        //     createStripeUser(UserId)
        // }
    }, [dwollaCustomerResponse])

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
        businessClassifications()
    }, [])

    useEffect(() => {
        if (dwollabusinessClassifications && dwollabusinessClassifications.length > 0)
            setBusinessClassifications(
                dwollabusinessClassifications[2]['_embedded']['industry-classifications'],
            )
    }, [dwollabusinessClassifications])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    useEffect(() => {
        formik.values.user_type = userType
    }, [userType])

    useEffect(() => {
        if (inType) {
            formik.values.socialSecurityType = inType
            // formik.setFieldValue('socialsecurity', '')
        }
    }, [inType])

    useEffect(() => {
        if (userType === 0) setInType('SSN')
    }, [userType])

    const viewTerms = () =>
        window.open(
            termsLink
                ? `${global.site_url}/uploads/static_files/${termsLink}`
                : '/assets/docs/termsOfService.pdf',
        )

    // Set Iniaital values
    const formik = useFormik({
        initialValues: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: 'US',
            zip: '',
            termCheck: 0,
            regType: 'individual',
            domicile: '',
            username_msg: 'Registration Failed! Record Label Name Already Exists.',
            domesticYN: '',
            companyname: '',
            username: '',
            entityType: '',
            entityDesc: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '+1',
            dob: '',
            socialsecurity: '',
            password: '',
            confirm_password: '',
            status: 'active',
            stripe_id: '',
            user_type: userType,
            socialSecurityType: 'SSN',
            is_marketing_opt: 0,
            utm_source: localStorage.getItem('utm_source') || '',
            utm_medium: localStorage.getItem('utm_medium') || '',
            utm_campaign: localStorage.getItem('utm_campaign') || '',
            converted: 0,
            Abandons_Registration: false,
            Type: 'Registration',
            zoho_id: '',
            Relationship: ['Registration'],
        },
        validationSchema: validationArray[0],
        onSubmit: (data) => {
            let values = data
            console.log(values, 'registerValues')
            if (localStorage.temp_user_id) {
                values.temp_user_id = localStorage.temp_user_id
            }
            if (localStorage.ttw_temp_user_id) {
                values.ttw_temp_user_id = localStorage.ttw_temp_user_id
            }
            setIsLoading(true)
            register(values)
        },
    })

    useEffect(() => {
        let values = props.regValues
        if (props?.regValues?.regType === 'individual') {
            setUserType(0)
        } else {
            setUserType(1)
        }
        console.log(props.regValues, 'regValues')
        formik.setFieldValue('regType', values.regType)
        formik.setFieldValue('domicile', values.domicile)
        formik.setFieldValue('domesticYN', values.domesticYN)
        formik.setFieldValue('companyname', values.companyname)
        formik.setFieldValue('username', values.username)
        formik.setFieldValue('entityType', values.entityType)
        formik.setFieldValue('entityDesc', values.entityDesc)
        formik.setFieldValue('first_name', values.first_name)
        formik.setFieldValue('last_name', values.last_name)
        formik.setFieldValue('email', values.email)
        formik.setFieldValue('phone', values.phone)
        formik.setFieldValue('dob', values.dob)
        formik.setFieldValue('socialsecurity', values.socialsecurity)
        formik.setFieldValue('password', values.password)
        formik.setFieldValue('confirm_password', values.confirm_password)
        formik.setFieldValue('status', values.status)
        formik.setFieldValue('stripe_id', values.stripe_id)
        formik.setFieldValue('user_type', values.user_type)
        formik.setFieldValue('socialSecurityType', values.socialSecurityType)
        formik.setFieldValue('is_marketing_opt', values.is_marketing_opt)
        formik.setFieldValue('converted', values.converted)
        formik.setFieldValue('Abandons_Registration', values.Abandons_Registration)
        formik.setFieldValue('zoho_id', values.zoho_id)
    }, [props.regValues])

    useEffect(() => {
        if (table_data.from === randomVariable) {
            if (table_data.records && table_data.records.length) {
                setZohoRecord({
                    email: table_data.records[0]?.email,
                    utm_source: table_data.records[0]?.utm_source,
                    utm_medium: table_data.records[0]?.utm_medium,
                    utm_campaign: table_data.records[0]?.utm_campaign,
                    zoho_id: table_data.records[0]?.id,
                    created_at: table_data.records[0]?.created_at,
                })
            } else {
                setZohoRecord({
                    email: formik.values.email,
                    utm_source: null,
                    utm_medium: null,
                    utm_campaign: null,
                    zoho_id: null,
                    created_at: null,
                })
            }

            formik.submitForm()
        }
    }, [table_data])

    useEffect(() => {
        if (localStorage.getItem('utm_source')) {
            formik.setFieldValue('utm_source', localStorage.getItem('utm_source'))
        }
    }, [localStorage.getItem('utm_source')])

    useEffect(() => {
        if (localStorage.getItem('utm_medium')) {
            formik.setFieldValue('utm_medium', localStorage.getItem('utm_medium'))
        }
    }, [localStorage.getItem('utm_medium')])

    useEffect(() => {
        if (localStorage.getItem('utm_campaign')) {
            formik.setFieldValue('utm_campaign', localStorage.getItem('utm_campaign'))
        }
    }, [localStorage.getItem('utm_campaign')])

    // const zohoFormik = useFormik({
    //     initialValues: {
    //         first_name: '',
    //         last_name: '',
    //         email: '',
    //         phone: '+1',
    //     },
    //     onSubmit: (values) => {
    //         console.log('zoho valuesssss--->', values)
    //         try {
    //             addCampaignContact(values, 'Subscriber', true)
    //         } catch (err) {
    //             console.log('Zoho Contact Err =>', err)
    //         }
    //     },
    // })

    // useEffect(() => {
    //     if (responseStatusNorthCapital) {
    //         if (responseStatusNorthCapital.from === 'addCampaignContact') {
    //             window.location.href = 'https://www.songvest.com/registered'
    //         }
    //     }
    // }, [responseStatusNorthCapital])

    useEffect(() => {
        if (formik.isSubmitting && Object.keys(formik.errors).length) {
            if (document.getElementsByName(Object.keys(formik.errors)[0]).length) {
                document.getElementsByName(Object.keys(formik.errors)[0]).forEach((element) => {
                    element.offsetParent !== null && element.focus()
                })
            }
        }
    }, [formik.errors, formik.isSubmitting])

    const onHandleUser = (event, value) => {
        if (!/\s/.test(event.target.value)) {
            formik.setFieldValue('username', event.target.value)
        }
    }

    // const recordLabel = [
    //     {
    //         label: 'What should we call your Songvest Record Label?',
    //         name: 'username',
    //         type: 'text',
    //         placeholder: 'Enter your record label name',
    //         class: `col-12 col-sm-6 helper`,
    //         onChange: onHandleUser,
    //         formik: formik,
    //         // helperText: `${process.env.REACT_APP_DOMAIN}/recordlabel/${formik.values.username}`,
    //         helperText: (
    //             <a
    //                 href={`${process.env.REACT_APP_FRONTEND_URL}recordlabel/${formik.values.username}`}
    //                 target="_blank"
    //                 rel="noreferrer"
    //                 className="ru-con"
    //             >
    //                 {' '}
    //                 {`${process.env.REACT_APP_FRONTEND_URL}recordlabel/${formik.values.username}`}
    //             </a>
    //         ),
    //         //     (
    //         //     <CustomPopover
    //         //         label={`${process.env.REACT_APP_DOMAIN}/recordlabel/${formik.values.username}`}
    //         //         content=" The first step in registration is naming your Record Label. As you
    //         //                 purchase SongShares, they will show up in your Record Label’s dashboard
    //         //                 along with your royalty payments. The SongShares you own will be
    //         //                 featured on a public SongVest URL you can share so friends can follow
    //         //                 along. Note: Your public dashboard and royalty payments can optionally
    //         //                 be hidden."
    //         //     />,
    //         // ),
    //         upperLabel: true,
    //     },
    // ]

    const registrationTypes = [
        {
            name: 'regType',
            label: 'Individual',
            id: 'individual',
            img: '/assets/svg/individual.svg',
        },
        {
            name: 'regType',
            label: 'Entity',
            id: 'company',
            img: '/assets/svg/entity.svg',
        },
    ]

    // Mapped over for individual registration fields
    const IndividualRegistration = []

    // if (userType !== 0)
    //     IndividualRegistration.push({
    //         label: 'EIN / SSN',
    //         name: 'socialsecurity',
    //         placeholder: 'Enter EIN / SSN',
    //         class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''} ${
    //             formik.values.domicile == 'non-resident' ? 'd-none' : ''
    //         }`,
    //         formik: formik,
    //         type: 'hiddenMask',
    //         maskValue: '**-*******',
    //         upperLabel: true,
    //         helperText: (
    //             <CustomPopover
    //                 label="Why do we need this?"
    //                 content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
    //             />
    //         ),
    //         // tooltiptitle:
    //         //     'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    //     })

    useEffect(() => {
        if (formik.values.domesticYN && formik.values.domesticYN === 'domestic_account') {
            formik.setFieldValue('country', 'US')
        }
        if (
            formik.values.regType === 'individual' &&
            formik.values.domesticYN === 'domestic_account'
        ) {
            setSSN(false)
        } else {
            setSSN(true)
        }
    }, [formik.values.regType, formik.values.domesticYN])

    useEffect(() => {
        if (formik.values.domicile === 'non-resident') {
            formik.setFieldValue('domesticYN', 'international_account')
        } else {
            formik.setFieldValue('domesticYN', 'domestic_account')
        }
    }, [formik.values.domicile])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'register') {
                if (responseStatus.status === 'success') {
                    formik.values.First_Name = formik.values.first_name
                    formik.values.Last_Name = formik.values.last_name
                    formik.values.Email = formik.values.email
                    formik.values.Phone = formik.values.phone
                    formik.values.Domicilex = formik.values.domicile
                    formik.values.Account_Type =
                        formik.values.domesticYN === 'domestic_account'
                            ? 'Domestic (US)'
                            : 'International'
                    formik.values.Date_of_Birth = moment(formik.values.dob).isValid()
                        ? `${moment(formik.values.dob, 'MM-DD-YYYY').format('YYYY-MM-DD')}`
                        : ''
                    formik.values.Mailing_Street = `${formik.values.address1} ${formik.values.address2}`
                    formik.values.Mailing_State = formik.values.state
                    formik.values.Mailing_City = formik.values.city
                    formik.values.Mailing_Country = formik.values.country
                    formik.values.Mailing_Zip = formik.values.zip

                    formik.values.Shipping_Street = `${formik.values.address1} ${formik.values.address2}`
                    formik.values.Shipping_State = formik.values.state
                    formik.values.Shipping_City = formik.values.city
                    formik.values.Shipping_Country = formik.values.country
                    formik.values.Shipping_Zip = formik.values.zip

                    // formik.values.Email_Opt_In = true
                    formik.values.Consent = 'Email'
                    formik.values.ource = 'Sign Up'
                    formik.values.User_Type = formik.values.regType
                    formik.values.Pick_List_5 = formik.values.regType
                    addCampaignContact(formik.values, 'Registered', true, true)
                    searchTableAction({
                        table: 'zoho_users_logs',
                        registered: 1,
                        updateKeys: 'email',
                        updateValues: `'${formik.values.email}'`,
                        noConfig: true,
                    })
                    // Dwollo integration
                    //createDwollaCustomer(responseStatus.data.userID) //disable dwollo wallet
                    createStripeUser(responseStatus.data.userID) // enable when dwollo is disable
                    setUserId(responseStatus.data.userID)

                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                        pageFormSubmission: 'Complete Registration',
                        userEmail: formik.values.email,
                        userFirstName: formik.values.first_name,
                        userLastName: formik.values.last_name,
                        userPhone: formik.values.phone,
                        userDOB: formik.values.dob,
                        userAddress: formik.values.address1,
                        userState: formik.values.state,
                        userCountry: formik.values.country,
                        userZip: formik.values.zip,
                    })
                    console.log(['Complete Registration', window.dataLayer])
                    const values = { ...formik.values }
                    const country = allCountries.find((e) => e.isoCode === values.country)
                    const state = allStates.find(
                        (e) => e.countryCode === values.country && e.isoCode === values.state,
                    )
                    values.country = country.name
                    values.state =
                        values.domesticYN === 'international_account' &&
                        values.domicile == 'non-resident'
                            ? 'NOUS'
                            : state && state.name
                            ? state.name
                            : 'NOUS'

                    const klaviyoPostData = JSON.parse(JSON.stringify(values))
                    console.log('klaviyoPostData', klaviyoPostData)
                    // updateProfileAfterRegistration(klaviyoPostData)
                    // subscribeProfileToListAfterRegister2(klaviyoPostData, true)
                    if (
                        responseStatus?.data?.ttwRes &&
                        responseStatus.data?.ttwRes?.length &&
                        localStorage.ttw_temp_user_id
                    ) {
                        if (
                            responseStatus.data?.ttwRes[0]?.current_price &&
                            responseStatus.data?.ttwRes[0].current_price !=
                                responseStatus.data?.ttwRes[0].old_price
                        ) {
                            localStorage.setItem(
                                'bidIncrease',
                                JSON.stringify({
                                    proposed_amount: localStorage.share,
                                    id: localStorage.itemId,
                                    bidding_type: 'hard',
                                    proposed_qty: '',
                                    customBidData: {
                                        autopurchase: localStorage.autopurchase,
                                    },
                                    autopurchase: localStorage.autopurchase,
                                }),
                            )
                            localStorage.setItem(
                                'current_price',
                                responseStatus.data?.ttwRes[0].current_price,
                            )
                            localStorage.setItem(
                                'old_price',
                                responseStatus.data?.ttwRes[0].old_price,
                            )
                        }
                    }
                } else {
                    setIsLoading(false)
                }
            } else if (responseStatus.from === 'checkValidation') {
                if (responseStatus.status !== 'success') {
                } else {
                    setActiveStep(1)
                    window.scrollTo(0, 200)
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    //     IndividualRegistration.push({
    //         label: 'Social Security Number',
    //         name: 'socialsecurity',
    //         type: 'hiddenMask',
    //         maskValue: '***-**-****',
    //         placeholder: 'Enter Social Security Number',
    //         class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''} ${
    //             formik.values.domicile == 'non-resident' ? 'd-none' : ''
    //         }`,
    //         formik: formik,
    //         upperLabel: true,
    //         helperText: (
    //             <CustomPopover
    //                 label="Why do we need this?"
    //                 content="We need to collect your SSN to verify your identity in order to make your royalty distribution payments."
    //             />
    //         ),
    //     })
    // {
    //     label: 'EIN / SSN',
    //     name: 'socialsecurity',
    //     type: 'number',
    //     placeholder: 'Enter EIN / SSN',
    //     class: `col-12 col-sm-6 ${userType === 0 ? 'd-none' : ''}`,
    //     formik: formik,
    //     tooltiptitle:
    //         'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    // },
    // {
    //     label: 'Social Security Number',
    //     name: 'socialsecurity',
    //     type: 'number',
    //     placeholder: 'Enter Social Security Number',
    //     class: `col-12 col-sm-6 ${userType === 1 ? 'd-none' : ''}`,
    //     formik: formik,
    //     tooltiptitle:
    //         'Since we are registered with an SEC regulated broker-dealer. Rally is required by federal law to ask for your Social Security Number',
    // },
    IndividualRegistration.push(
        {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            placeholder: 'Enter your address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            placeholder: 'Enter your address',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'City',
            name: 'city',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-12 col-sm-6',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'State',
            placeholder: 'Select your state',
            class: `col-12 col-sm-6${
                formik.values.country != '' &&
                formik.values.country != 'US' &&
                formik.values.country != 'CA'
                    ? ' d-none'
                    : ''
            }`,
            type: 'select',
            options: allStates
                .filter(
                    (state) =>
                        (formik.values.country
                            ? formik.values.country.indexOf(state.countryCode) !== -1
                            : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                        global.ignoreStates.indexOf(state.isoCode) === -1,
                )
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.isoCode
                    return busCategoryChanged
                }),
            name: 'state',
            formik: formik,
            upperLabel: true,
        },
        {
            label: 'Country',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: allCountries
                .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                .map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.isoCode
                    return busCategoryChanged
                }),
            name: 'country',
            formik: formik,
            disabled: formik.values.domesticYN === 'domestic_account' ? true : false,
            upperLabel: true,
        },
        {
            label: 'Zip code',
            placeholder: 'Enter your zip code',
            class: 'col-12 col-sm-6',
            type: 'text',
            name: 'zip',
            formik: formik,
            upperLabel: true,
        },
        {
            label: [
                'I agree to accept the ',
                <a href="https://www.songvest.com/terms-of-use" className="ml-1" target="blank">
                    {' '}
                    {' Terms & Conditions'}
                </a>,
                '',
            ],
            name: 'termCheck',
            type: 'checkbox',
            class: 'col-12 mt-1 chkBoxWrpr',
            formik: formik,
        },
    )
    useEffect(() => {
        setCurrentCountry(formik.values.country)
        if (formik.values.country) formik.setFieldValue('state', '')
        if (document.getElementById('register'))
            document.getElementById('register').className = 'active'
    }, [formik.values.country])

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            let userDetails = user

            formik.setFieldValue('first_name', userDetails.first_name ? userDetails.first_name : '')
            formik.setFieldValue('last_name', userDetails.last_name ? userDetails.last_name : '')
            formik.setFieldValue('email', userDetails.email ? userDetails.email : '')
            formik.setFieldValue('phone', userDetails.phone ? userDetails.phone : '')
            formik.setFieldValue(
                'is_marketing_opt',
                userDetails.is_marketing_opt ? userDetails.is_marketing_opt : '',
            )
            formik.setFieldValue('domesticYN', userDetails.domesticYN ? userDetails.domesticYN : '')
        }
    }, [user])

    return (
        <>
            <Loaders name="home" isLoading={isLoading} loop={1} className="defaultLoader" />
            <div className="loginWrapper p-5 h-100">
                <form className="registerForm mb-0 row" onSubmit={formik.handleSubmit}>
                    <div className="row mt-4">{Object.values(mapData(IndividualRegistration))}</div>
                    <div className="regActBox mt-2">
                        <PrimaryButton
                            id="register_button"
                            label="Register"
                            type="submit"
                            onClick={() => formik.handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default FinalStep
