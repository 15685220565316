import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../../product/components/atoms/Inputs/CustomMultiSelect'
import MenuItem from '@material-ui/core/MenuItem'
import CustomInput from '../../product/components/atoms/Inputs/CustomInput'
import CustomPhone from '../../product/components/atoms/Inputs/CustomPhone.js'
import CheckBox from '../../product/components/atoms/CheckBox'
import CustomTextArea from '../../product/components/atoms/Inputs/CustomTextArea'
import CKEditor from 'ckeditor4-react'
import { socket } from '../../product/common/socket'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

let serverTime = new Date()
const dateFormat = 'MM-DD-YYYY'
const dateTimeFormat = 'MM-DD-YYYY h:mm a'

if (socket) {
    socket.on('sliservertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

export const handleRedirectInternal = (history, path) => {
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}
export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const capitalize = (data) => {
    return data ? data.charAt(0).toUpperCase() + data.slice(1) : '-'
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const currencyFormat = (data) => {
    return `$${parseFloat(data).toFixed(2).toLocaleString('en-US')}`
}

export const numberFormatPositive = (data) => {
    return `${data < 0 ? 0 : data}`
}

export const currencyFormatInt = (data) => {
    return `${
        parseInt(data).toLocaleString('en-US') !== 'NaN'
            ? '$' + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : ''
    }`
}

export const currencyFormatFloat = (data) => {
    return `${
        parseFloat(parseFloat(data).toFixed(2)).toLocaleString('en-US') !== 'NaN'
            ? '$' +
              parseFloat(data)
                  .toFixed(2)
                  .toLocaleString('en-US')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : ''
    }`
}

export const currencyFormatTwo = (data) => {
    return `$${parseFloat(parseFloat(data)).toFixed(2).toLocaleString('en-US')}`
}

export const currencyFormatTwoNoDollar = (data) => {
    return `${parseFloat(parseFloat(data)).toFixed(2).toLocaleString('en-US')}`
}

export const numberFormat = (data) => {
    return `${
        parseFloat(parseFloat(data).toFixed(2)).toLocaleString('en-US') !== 'NaN'
            ? parseFloat(parseFloat(data).toFixed(2)).toLocaleString('en-US')
            : ''
    }`
}

export const numberFormatCommaSeparate = (data) => {
    return `${
        parseFloat(parseFloat(data).toFixed(2)).toLocaleString('en-US') !== 'NaN'
            ? data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : ''
    }`
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const dateFormatFrontOnlyDate = (data) => {
    return `${moment(data).format(dateFormat)}`
}

export const dateTimeFormatFunction = (data) => {
    return `${moment(data).format(dateTimeFormat)}`
}

export const DirectAPICAll = (method, url) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (method === 'get') {
                let res = await axios.get(`${url}`)
                resolve(res)
            } else if (method === 'post') {
                let res = await axios.post(`${url}`)
                resolve(res)
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}
